export * from './enums/MessageType';
export * from './enums/MessageContentType';
export * from './enums/MessageStatus';

export * from './interfaces/IBaseMessage';
export * from './interfaces/IMessage';
export * from './interfaces/IFadingPhotoMessage';
export * from './interfaces/IContactMessage';

export * from './BaseMessage';
export * from './Message';
export * from './FadingPhotoMessage';
export * from './ContactMessage';

export * from './guards/message';
