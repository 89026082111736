import { defineComponent } from '@vue/runtime-core';
import { IconName, IconType } from 'components/Icon';
import Prompt from 'components/Prompt';
import { MainColor, Size } from 'core/styles';
import { FileSource } from 'core/types';
import { ChatsAction } from 'store/chats/enums';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { computed, ref, watch } from 'vue';
import { useApiErrors } from 'composables/apiErrors';

const PhotoMessage = defineComponent({
  components: {
    Prompt,
  },

  props: ['message', 'isSelf', 'isScrolled'],

  setup(props) {
    const { showError } = useApiErrors();
    const viewerImages = computed<FileSource[]>(() =>
      props.message.content.photo.map((path: any, i: number) => ({
        id: i,
        path,
      })));
    const imageRef = ref<HTMLImageElement>();
    const isShowViewer = ref(false);
    const viewerImagesActiveIndex = ref(0);
    const deleteMessage = useGenerateChatsAction<{ userId: string; messageId: number }>(ChatsAction.DeleteMessage);

    const handleShowPhoto = (key: number) => {
      viewerImagesActiveIndex.value = key;
      isShowViewer.value = true;
    };

    const closeAccess = (userId: string, messageId: number) => {
      deleteMessage({
        userId,
        messageId,
      }).catch((e) => showError(e));
    };

    const handleCloseViewer = () => {
      isShowViewer.value = false;
      viewerImagesActiveIndex.value = 0;
    };

    const delay = ref(false);

    if (props.isScrolled) {
      delay.value = true; // @todo а если новое (фото) сообщение?
    } else {
      const stopWatcher = watch(() => props.isScrolled, (value) => {
        if (value) {
          setTimeout(() => {
            delay.value = true;
          }, 1500); // @todo костыль, переделать
          stopWatcher();
        }
      });
    }

    function getWrapperWidth(key: number) {
      if (key < 3 || key >= 7) return `calc(${100 / 3}% - 3px)`;
      return `calc(${100 / 4}% - 4px)`;
    }

    const wrapperHeight = computed(() => {
      if (props.message.content.photo.length <= 3) return 'calc(100% - 2px)';
      if (props.message.content.photo.length <= 7) return 'calc(50% - 2px)';
      return `calc(${100 / 3}% - 3px)`;
    });

    const alonePhoto = computed(() => props.message?.content?.photo?.length === 1);
    const alonePhotoRatio = ref(1);

    const loadedCount = ref(0);
    function onLoaded() {
      if (alonePhoto.value && imageRef.value) {
        alonePhotoRatio.value = imageRef.value.naturalWidth / imageRef.value?.naturalHeight;
        if (alonePhotoRatio.value > 3) alonePhotoRatio.value = 3;
        else if (alonePhotoRatio.value < 1 / 3) alonePhotoRatio.value = 1 / 3;
      }
      loadedCount.value++;
    }

    return {
      imageRef,
      alonePhoto,
      alonePhotoRatio,
      delay,
      isShowViewer,
      viewerImages,
      viewerImagesActiveIndex,
      handleCloseViewer,
      getWrapperWidth,
      wrapperHeight,
      loadedCount,
      onLoaded,
      IconName,
      IconType,
      Size,
      MainColor,
      handleShowPhoto,
      closeAccess,
    };
  },
});

export default PhotoMessage;
