import { ContactMessage } from '../ContactMessage';
import { MessageType } from '../enums/MessageType';
import { FadingPhotoMessage } from '../FadingPhotoMessage';
import type { IBaseMessage } from '../interfaces/IBaseMessage';
import { Message } from '../Message';

export const isMessage = (value: IBaseMessage): value is Message =>
  value.type === MessageType.Message;

export const isFadingPhotoMessage = (value: IBaseMessage): value is FadingPhotoMessage =>
  value.type === MessageType.FadingPhoto;

export const isContactMessage = (value: IBaseMessage): value is ContactMessage =>
  value.type === MessageType.Contact;
