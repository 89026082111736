import { defineComponent } from 'vue';
import { Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';

const BottomModal = defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    function close() {
      emit('close');
    }

    return {
      close,
    };
  },
  data() {
    return {
      Size,
      IconName,
      IconType,
    };
  },
});

export default BottomModal;
