<template>
  <bottom-modal
    class="room-add-contact"
    :show="isShow"
    :title="$t('pages.room.addContacts.title')"
    @close="close"
  >
    <p>{{ $t('pages.room.addContacts.content') }}</p>
    <add-contacts
      class="room-add-contact__add-contacts"
      @valid="validContacts = $event"
    />
    <btn
      w="100%"
      class="room-add-contact__button"
      :disabled="isBtnDisabled"
      @click="onClick"
    >
      {{ $t('pages.room.addContacts.submit') }}
    </btn>
  </bottom-modal>
</template>

<script lang="ts" src="./RoomAddContact.ts" />
<style lang="scss" src="./roomAddContact.scss" />
