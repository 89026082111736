import { defineComponent, h, PropType, toRefs } from '@vue/runtime-core';
import { MessageType, TrueMessageType } from 'api/rooms/models/message';
import { formatToChat } from 'core/date-time/time';
import { computed, watchEffect } from 'vue';
import { IconName } from 'components/Icon';
import PlainTextMessage from './PlainTextMessage/PlainTextMessage.vue';
import ContactMessage from './ContactMessage/ContactMessage.vue';
import PhotoMessage from './PhotoMessage/PhotoMessage.vue';
import PrivatePhotoMessage from './PrivatePhotoMessage';
import CancelMessageButton from './CancelMessageButton/CancelMessageButton.vue';
import RetryMessageButton from './RetryMessageButton/RetryMessageButton.vue';
import ButtonMessage from './ButtonMessage/ButtonMessage.vue';
import MessageStatus from './MessageStatus';
import { getOrderTaxiUrl } from './Helpers';

interface IProfile {
  id: string,
}

const ChatMessage = defineComponent({
  props: {
    message: {
      type: Object,
      required: true,
    },
    isSelf: {
      type: Boolean,
      required: true,
    },
    profile: {
      type: Object as PropType<IProfile>,
      required: true,
    },
    isScrolled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['hidden-contact-click'],
  setup(props, { emit }) {
    const { message, isSelf } = toRefs(props);
    let children: any[] = [];
    let classes = {};
    let isContact = false;

    const onContactClick = () => {
      if (message.value?.contact_is_hidden) emit('hidden-contact-click');
    };

    watchEffect(() => {
      children = [];
      let isMessageTypeUnknown = false;
      const messageHasAction = (msg: any) => {
        if (isSelf.value && msg.type === 'private_photo') return true;
        if (msg.type === 'button' && msg.content.button) return true;
        if (msg.state === 'error') return true;
        if (
          msg.type === TrueMessageType.Address
          && !isSelf.value
          && getOrderTaxiUrl(msg.content)
        ) return true;
        return false;
      };

      const isStoryMessage = computed(() => props.message?.refer === 'stories');

      switch (message.value.type) {
        case TrueMessageType.Address:
        case TrueMessageType.Phone:
        case TrueMessageType.Telegram:
        case TrueMessageType.Whatsapp:
          children.push(h(ContactMessage, { message: message.value, isSelf: isSelf.value }));
          isContact = true;
          break;
        case 'photo':
          children.push(h(PhotoMessage, { message: message.value, isSelf: isSelf.value, isScrolled: props.isScrolled }));
          break;
        case 'private_photo':
          children.push(h(PrivatePhotoMessage, { message: message.value, isSelf: isSelf.value }));
          break;
        case 'button':
          children.push(h(ButtonMessage, { message: message.value, isSelf: isSelf.value }));
          break;
        case MessageType.FadingPhoto.toString():
          // children.push(h(FadingPhotoMessage, { message: message.value, isSelf: isSelf.value }));
          break;
        case TrueMessageType.Text:
          children.push(h(PlainTextMessage, { message: message.value, isSelf: isSelf.value, isStory: isStoryMessage.value }));
          break;
        default:
          isMessageTypeUnknown = true;
          children.push(h('div', { style: 'text-align: center' }, [
            h('div', {}, 'Неизвестный тип сообщения.'),
            h('button', { class: 'btn btn_primary', onclick: 'window.location.reload()' }, [' Обновить страницу']),
          ]));
          break;
      }

      const isError = message.value.state?.toString() === 'error';
      const isNotSentMessage = message.value.state?.toString() === 'new';

      const statusIconName = computed(() => {
        if (props.isSelf) {
          if (props.message.read_at) return IconName.DoubleMark;
          return IconName.Mark;
        }
        if (!props.message.read_at) return IconName.Dot;
        return '';
      });

      children.push(h(MessageStatus, { class: 'room-chat__status', iconName: statusIconName.value, time: formatToChat(new Date(message.value.created_at)) }));

      if (isError) {
        children.push(h(RetryMessageButton, { userId: message.value.recipient_id, messageId: message.value.id }));
        children.push(h(CancelMessageButton, { userId: message.value.recipient_id, messageId: message.value.id }));
      } else if (isNotSentMessage) {
        children.push(h('div', { class: 'room-chat__spinner' }));
      }

      classes = {
        'room-chat__message': true,
        'room-chat__message_story': isStoryMessage.value,
        'room-chat__message_text': true,
        'room-chat__message_contact': isContact,
        'room-chat__message_contact--hidden': message.value.contact_is_hidden,
        'room-chat__message_unsent': isNotSentMessage && !isError,
        'room-chat__message_error': isError,
        'room-chat__message_interlocutor': !isSelf.value,
        'room-chat__message_with-action': messageHasAction(message.value),
        'room-chat__message_single-photo': message.value.type === 'photo' && message.value.content?.photo?.length === 1,
        'room-chat__message_single-photo--self': message.value.type === 'photo' && isSelf.value && message.value.content?.photo?.length === 1,
        'room-chat__message_type_unknown': isMessageTypeUnknown,
        [`room-chat__message_${message.value.type}`]: true,
      };
    });

    return () => h('div', { class: classes, onClick: isContact ? onContactClick : undefined }, children);
  },
});

export default ChatMessage;
