import { render } from "./BlockedUser.vue?vue&type=template&id=279f4f97"
import script from "./BlockedUser.ts?vue&type=script&lang=ts"
export * from "./BlockedUser.ts?vue&type=script&lang=ts"

import "./blockedUser.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "279f4f97"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('279f4f97', script)) {
    api.reload('279f4f97', script)
  }
  
  module.hot.accept("./BlockedUser.vue?vue&type=template&id=279f4f97", () => {
    api.rerender('279f4f97', render)
  })

}

script.__file = "src/views/Rooms/components/BlockedUser/BlockedUser.vue"

export default script