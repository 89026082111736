import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { isLovistaAdmin } from 'core/helpers/isLovistaAdmin';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { Conversation } from 'api/chatsService';
import { formatToChat } from 'core/date-time';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { useI18n } from 'vue-i18n';

const Message = defineComponent({
  props: {
    message: {
      type: Object as PropType<Conversation>,
      required: true,
    },
  },

  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    const profile = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const formatToChatTime = (dateStr: string) => formatToChat(new Date(dateStr));
    const user = computed(() => (props.message.opposite_user));

    const selfMessage = computed(() => profile.value.id === props.message.last_message?.sender_id);

    const statusIconName = computed(() => {
      if (selfMessage.value) {
        if (props.message.last_message?.read_at) return IconName.DoubleMark;
        return IconName.Mark;
      }
      if (!props.message.last_message?.read_at) return IconName.Dot;
      return '';
    });

    const fullName = computed(() => {
      const { name, age, id, deleted_at, blocked_at } = user.value;
      if (isLovistaAdmin(id) || deleted_at || blocked_at) return name;
      return `${name}, ${age} ${t('user.age', age)}`;
    });

    return {
      user,
      fullName,
      selfMessage,
      statusIconName,

      formatToChatTime,
      isLovistaAdmin,

      IconName,
      IconType,
      Size,
      TxtWeight,
      TxtType,
    };
  },
});

export default Message;
