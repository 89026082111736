<template>
  <room-prompt
    :show="show"
    class="block-user"
    @close="close"
  >
    <avatar
      class="mt-4"
      :user="user"
    />
    <txt class="p-4">
      {{ $t('pages.room.blockUser.text', { name: user.name, sex: $t(`pages.room.blockUser.${user.gender}`) }) }}
      <br>
      {{ $t('pages.room.blockUser.question', { sex: $t(`pages.room.blockUser.whom.${user.gender}`) }) }}
    </txt>
    <btn
      :type="BtnType.Text"
      w="100%"
      center
      :disabled="isFetching"
      @click="blockUser"
    >
      {{ $t('pages.room.blockUser.block') }}
    </btn>
  </room-prompt>
</template>

<script lang="ts" src="./BlockUser.ts" />
