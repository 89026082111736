<template>
  <expanded-alert
    v-if="isShow && packages.length"
    ref="alertRef"
    :icon-type="IconType.Contour"
    :icon-name="IconName.PhonePerson"
  >
    <p
      class="view-contacts-alert__text"
      @click="showPopup"
    >
      {{ $t('pages.room.expandedAlert.title') }}
    </p>
  </expanded-alert>
</template>

<script lang="ts" src="./ViewContactsAlert.ts" />
<style lang="scss" src="./viewContactsAlert.scss" />
