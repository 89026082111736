<template>
  <prompt
    :model-value="modelValue"
    :ok-text-path="$t('pages.room.paidRemoveChat.confirm', { price: formattedPrice })"
    @update:modelValue="$emit('update:modelValue', $event)"
    @ok="removeChat"
  >
    <txt class="mb-2" :type="TxtType.Heading4">
      {{ $t('pages.room.paidRemoveChat.title') }}
    </txt>
    <txt>
      {{ $t('pages.room.paidRemoveChat.content', { name: user.name }) }}
    </txt>
  </prompt>
</template>

<script lang="ts" src="./PaidRemoveChat.ts" />
