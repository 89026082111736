export enum MessageType {
  Message,
  Contact,
  FadingPhoto,
}

export enum TrueMessageType {
  Text = 'text',
  Phone = 'phone',
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  Address = 'address',
}
