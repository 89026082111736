<template>
  <prompt
    v-model="model"
    :title="$t('pages.room.needPackage.title')"
    ok-text-path="pages.room.needPackage.ok"
    @ok="onOk"
  />
</template>

<script lang="ts" src="./NeedPackagePrompt.ts" />
