<template>
  <popup
    class="view-contacts-popup"
    :show="model"
  >
    <icon-btn
      class="view-contacts-popup__back"
      @click="closePopup"
    >
      <icon
        :name="IconName.ArrowBack"
        :type="IconType.Contour"
      />
    </icon-btn>
    <txt
      :type="TxtType.Heading2"
    >
      {{ $t('pages.room.expandedPopup.title') }}
    </txt>

    <div
      class="view-contacts-popup__img"
      v-html="IconContact"
    />

    <p>
      {{ $t('pages.room.expandedPopup.description') }}
    </p>

    <radio-group
      v-model="radioModel"
      class="view-contacts-popup__radio"
      :values="options"
      :border="false"
      value-key="value"
    >
      <template #default="{ value }">
        {{ value.title }}
      </template>
    </radio-group>

    <btn
      v-if="selected"
      w="100%"
      :disabled="isFetching"
      @click="buy"
    >
      {{ selected.btnText }}
    </btn>
  </popup>
</template>

<script lang="ts" src="./ViewContactsPopup.ts" />
<style lang="scss" src="./viewContactsPopup.scss" />
