import { ViewContactEventBus } from './viewContactEventBus';

export function useViewContact() {
  function showUserContacts() {
    ViewContactEventBus.emit('showUserContacts');
  }
  function showPrompt() {
    ViewContactEventBus.emit('openPrompt');
  }
  function showSpendPrompt(id: number) {
    ViewContactEventBus.emit('openSpendPrompt', id);
  }
  function showPopup() {
    ViewContactEventBus.emit('show');
  }
  function closePopup() {
    ViewContactEventBus.emit('close');
  }

  function onShowPopup(fn: () => void) {
    ViewContactEventBus.on('show', fn);
  }
  function onClosePopup(fn: () => void) {
    ViewContactEventBus.on('close', fn);
  }
  function onOpenPrompt(fn: () => void) {
    ViewContactEventBus.on('openPrompt', fn);
  }
  function onOpenSpendPrompt(fn: (id: number) => void) {
    ViewContactEventBus.on('openSpendPrompt', fn);
  }
  function onShowUserContacts(fn: () => void) {
    ViewContactEventBus.on('showUserContacts', fn);
  }

  return {
    showPopup,
    showSpendPrompt,
    showUserContacts,
    showPrompt,
    closePopup,
    onShowPopup,
    onClosePopup,
    onOpenPrompt,
    onOpenSpendPrompt,
    onShowUserContacts,
  };
}
