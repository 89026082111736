<template>
  <male-popup v-model="paidMessagesPopup" />

  <main
    v-if="user"
    v-touch:tap="handleTap"
    class="room"
  >
    <template v-if="profile.gender === Sex.Male">
      <view-contacts-alert
        v-if="!user.contacts_is_open && user.contacts_exist"
        @show-popup="viewContacts"
      />
      <view-contacts-popup
        :user-id="userId"
        @start="onViewPopupStartFetch"
        @end="onViewPopupEndFetch"
      />
      <need-package-prompt />
      <spend-package-prompt
        :user-id="userId"
        :contacts-exist="user.contacts_exist"
        @start="onSpendPackageStartFetch"
        @end="onSpendPackageEndFetch"
        @success="handleSpendPackageSuccess"
      />
      <user-contacts :contacts="user.contacts" />
    </template>

    <block-user
      :user="user"
      :show="isBlockDialogShow"
      @success="loadUser"
      @close="closeBlockDialog"
    />
    <remove-chat
      :user="user"
      :show="isShowDeleteChat"
      @close="closeDeleteChat"
      @success="onSuccessChatDeletion"
      @payment-required="onChatDeletionPaymentRequired"
    />
    <paid-remove-chat
      v-model="isShowPaidDeleteChat"
      :user="user"
      @success="onSuccessChatDeletion"
    />

    <add-private-photo
      v-model="isAddPrivatePhotoShow"
      @submit="isPhotoViewerOpen = true"
    />
    <room-add-contact
      v-model="isAddContactsShow"
      @submit="isAttachContactMode = true"
    />
    <header class="room-header">
      <icon-btn
        class="room-header__back"
        :color="MainColor.Black2"
        @click="$router.back()"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
        />
      </icon-btn>

      <div
        class="room-header__user"
        @click="toUserProfile"
      >
        <icon-btn
          v-if="profile.gender === Sex.Male"
          class="room-header__phone-icon"
          @click.stop="viewContacts"
        >
          <icon
            :name="IconName.Phone"
            :type="IconType.Fill"
          />
        </icon-btn>

        <txt
          tag="div"
          :type="TxtType.Heading4"
          class="room-header__user-name"
        >
          <div
            :class="{
              'room-user-status': !(user.deleted_at || user.blocked_at),
              'room-user-status_online': user.is_online,
            }"
          />

          <template v-if="isLovistaAdmin">
            {{ user.name }}
            <icon
              :type="IconType.Fill"
              :name="IconName.Verified"
              :size="Size.Xs"
            />
          </template>

          <template v-else-if="user.deleted_at || user.blocked_at">
            {{ user.name }}
          </template>

          <template v-else>
            {{ user.name }}, {{ `${user.age} ${$tc('user.age', user.age)}` }}
          </template>
        </txt>

        <txt
          tag="div"
          :type="TxtType.SmallText"
          :class="{
            'room-header__user-status': true,
            'room-header__user-status_online': user.is_online,
          }"
        >
          {{ getUserStatus(user) }}
        </txt>
      </div>

      <avatar
        class="room-header__avatar"
        :class="{ 'room-header__avatar--no-margin': isLovistaAdmin }"
        :has-plus="false"
        :size="Size.S"
        :user="user"
        @click="toUserProfile"
      />

      <context-menu
        v-if="!isLovistaAdmin"
        css-class="context_menu__room"
        :pos="1"
        :list="[
          {
            iconType: inFavorite ? IconType.Fill : IconType.Contour,
            iconName: IconName.Heart,
            text: inFavorite ? $t('pages.room.context.removeFromFavorite') : $t('pages.room.context.addToFavorite'),
            action: handleFavorite,
            isEnabled: !(user.deleted_at || user.blocked_at),
          },
          {
            iconType: IconType.Contour,
            iconName: IconName.Ban,
            text: $t('pages.room.context.block'),
            action: () => showBlockDialog(),
            isEnabled: !isLovistaAdmin && !user.is_blocked && !(user.deleted_at || user.blocked_at),
          },
          {
            iconType: IconType.Contour,
            iconName: IconName.Flag,
            text: $t('pages.room.context.complain'),
            action: () => isShowClaim = true,
            isEnabled: !(user.deleted_at || user.blocked_at),
          },
          {
            iconType: IconType.Contour,
            iconName: IconName.Delete,
            text: $t('pages.room.context.removeChat'),
            action: () => isShowDeleteChat = true,
            isEnabled: canDeleteChat,
          },
        ]"
      >
        <icon-btn
          class="room-header__more"
          :color="MainColor.Grey5"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Dots"
          />
        </icon-btn>
      </context-menu>
    </header>

    <div
      ref="roomChatRef"
      class="room-chat"
      @scroll="handleScroll"
    >
      <div
        ref="roomChatAnchorRef"
      />
      <chat-message
        v-for="message in messages"
        :id="`message-${message.id}`"
        :key="message.id + '-' + message.state"
        :message="message"
        :is-self="['new', 'error'].includes(`${message.state}`) || message.sender_id === profile.id"
        :profile="profile"
        :is-scrolled="isScrolled"
        @hidden-contact-click="viewContacts"
      />
      <txt
        v-if="!isMessagingAvailable()"
        tag="div"
        :type="TxtType.SubText"
        :weight="TxtWeight.Medium"
        class="room-chat__room_locked"
      >
        {{ $t('pages.room.unavailableMessage') }}
      </txt>
      <div id="room-chat-messages-end" />
      <div
        v-if="isInterlocutorTyping && !user.is_blocked"
        class="room-chat-interlocutor-typing"
      >
        <div class="room-chat-interlocutor-typing__dots_wrapper">
          <div class="room-chat-interlocutor-typing__dots" />
        </div>
      </div>
    </div>

    <div
      v-if="isAttachContactMode"
      ref="attachContactRef"
      class="attach-contact"
    >
      <contact-list
        :contacts="profile.contacts"
        @contact-click="handleContact"
      />
      <icon-btn
        class="attach-contact__close-btn"
        :size="Size.Xs"
        @click="isAttachContactMode = false"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Close"
          :color="MainColor.White"
          :size="Size.Xs"
        />
      </icon-btn>
    </div>

    <footer
      v-else-if="isMessagingAvailable()"
      ref="footerRef"
      :class="`footer ${isAttachMode ? 'footer_full' : ''}`"
    >
      <div
        v-if="uploadedPreview.length > 0"
        class="footer__uploaded"
      >
        <div
          v-for="(src, i) in uploadedPreview"
          :key="src"
          class="footer__uploaded-image-container"
        >
          <img
            class="footer__uploaded-image"
            :src="src"
          >

          <icon-btn
            class="footer__uploaded-close"
            :size="Size.S"
            @click="handleRemoveImage(i)"
          >
            <icon
              class="footer__uploaded-close-icon"
              :type="IconType.Contour"
              :name="IconName.Crosses"
              :size="Size.Xxs"
            />
          </icon-btn>
        </div>
      </div>

      <div
        v-if="user.is_blocked"
        class="user-layout-footer"
        @click="unblockUser"
      >
        <button
          type="button"
          class="btn btn_primary user-layout-footer__button"
          style="width: 100%;"
        >
          {{ $t('pages.room.context.unblock') }}
        </button>
      </div>

      <div
        v-else-if="!user.is_blocked"
        class="footer__main"
      >
        <icon-btn
          :class="{
            footer__plus: true,
            footer__plus_active: isAttachMode,
          }"
          :color="MainColor.Violet"
          @click="isAttachMode = !isAttachMode"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.Plus"
            :color="activeThemeColor"
          />
        </icon-btn>

        <textarea
          ref="inputRef"
          v-model="input"
          :placeholder="$t('pages.room.yourMessage')"
          class="footer__input"
          rows="1"
          maxlength="1000"
          @focus="handleTextareaFocus"
          @input="autoGrow"
          @keydown.enter="handleKeyDown"
          @keypress="handleTyping"
        />

        <icon-btn
          class="footer__send"
          :size="Size.L"
          @click="handleSend"
        >
          <icon
            :type="IconType.Fill"
            :name="IconName.ArrowRight"
            :color="isInputDirty || uploadedPreview.length > 0 ? activeThemeColor : MainColor.Grey3"
          />
        </icon-btn>
      </div>

      <div
        v-if="isAttachMode"
        class="footer__attach"
      >
        <div class="footer__attach-button footer__attach-photo">
          <div class="footer__attach-icon-container">
            <icon
              :type="IconType.Contour"
              :name="IconName.Gallery"
              :size="Size.S"
            />
          </div>

          <txt
            tag="div"
            :type="TxtType.SubText"
            :weight="TxtWeight.Medium"
            class="footer__icon-text"
          >
            {{ $t('pages.room.upToNPhotos', { n: 10 }) }}
          </txt>

          <input
            type="file"
            accept="image/png, image/jpeg"
            multiple
            class="footer__attach-photo-input"
            @change="handleUploadPhoto"
          >
        </div>

        <div
          class="footer__attach-button footer__attach-fade-photo"
          @click="openPrivatePhotos"
        >
          <div class="footer__attach-icon-container">
            <icon
              :type="IconType.Contour"
              :name="IconName.Rectangle"
              :size="Size.S"
            />
          </div>

          <txt
            tag="div"
            :type="TxtType.SubText"
            :weight="TxtWeight.Medium"
            class="footer__icon-text"
          >
            {{ $t('pages.room.privatePhotos') }}
          </txt>
        </div>

        <div
          class="footer__attach-button footer__attach-contact"
          @click="handleAttachContact"
        >
          <div class="footer__attach-icon-container">
            <icon
              :type="IconType.Contour"
              :name="IconName.Telegram"
              :size="Size.S"
            />
          </div>

          <txt
            tag="div"
            :type="TxtType.SubText"
            :weight="TxtWeight.Medium"
            class="footer__icon-text"
          >
            {{ $t('pages.room.contact') }}
          </txt>
        </div>
      </div>
    </footer>

    <modal
      :show="isShowClaim"
      :header="{
        title: $t('pages.room.chooseComplainReason'),
        dir: ModalHeaderDirection.Rtl,
      }"

      @close="isShowClaim = false"
    >
      <txt
        v-for="claim in claims"
        :key="claim"
        tag="div"
        :weight="TxtWeight.Medium"
        class="room-claim-item"
        @click="handleClaim(claim)"
      >
        {{ $t(`claims.${claim}`) }}
      </txt>
    </modal>

    <photo-viewer
      :show="isPhotoViewerOpen"
      :sources="privatePhotos"
      @close="isPhotoViewerOpen = false"
    >
      <template #footer>
        <div
          class="room-private-photos__footer"
        >
          <btn
            w="100%"
            :disabled="isPrivatePhotoFetching"
            class="room-private-photos__footer-btn"
            @click="handleSubmitPrivatePhoto"
          >
            {{ $t('pages.room.sendPrivatePhotos') }}
          </btn>
        </div>
      </template>
    </photo-viewer>
  </main>
</template>

<script lang="ts" src="./Room.ts"></script>
<style lang="scss" src="./room.scss"></style>
<style lang="scss" src="../../layouts/UserLayout/user-layout.scss"></style>
