import { render } from "./PlainTextMessage.vue?vue&type=template&id=def082d4"
import script from "./PlainTextMessage.ts?vue&type=script&lang=ts"
export * from "./PlainTextMessage.ts?vue&type=script&lang=ts"

import "./plainTextMessage.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "def082d4"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('def082d4', script)) {
    api.reload('def082d4', script)
  }
  
  module.hot.accept("./PlainTextMessage.vue?vue&type=template&id=def082d4", () => {
    api.rerender('def082d4', render)
  })

}

script.__file = "src/views/Room/messages/PlainTextMessage/PlainTextMessage.vue"

export default script