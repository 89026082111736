import { render } from "./PhotoMessage.vue?vue&type=template&id=7cf43536"
import script from "./PhotoMessage.ts?vue&type=script&lang=ts"
export * from "./PhotoMessage.ts?vue&type=script&lang=ts"

import "./photoMessage.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7cf43536"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7cf43536', script)) {
    api.reload('7cf43536', script)
  }
  
  module.hot.accept("./PhotoMessage.vue?vue&type=template&id=7cf43536", () => {
    api.rerender('7cf43536', render)
  })

}

script.__file = "src/views/Room/messages/PhotoMessage/PhotoMessage.vue"

export default script