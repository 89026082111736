import { render } from "./RoomPrompt.vue?vue&type=template&id=4f9d8c80"
import script from "./RoomPrompt.ts?vue&type=script&lang=ts"
export * from "./RoomPrompt.ts?vue&type=script&lang=ts"

import "./roomPrompt.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4f9d8c80"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4f9d8c80', script)) {
    api.reload('4f9d8c80', script)
  }
  
  module.hot.accept("./RoomPrompt.vue?vue&type=template&id=4f9d8c80", () => {
    api.rerender('4f9d8c80', render)
  })

}

script.__file = "src/views/Room/components/RoomPrompt/RoomPrompt.vue"

export default script