<template>
    <a
        class="room-chat__message-action"
        v-on:click.prevent="cancelMessage()"
        href="#"
    >
    Отменить отправку
    </a>
</template>

<script type="ts" src="./CancelMessageButton.ts"></script>
