import { Contact } from '../../../users/models/contact';
import { BaseMessage } from './BaseMessage';
import { MessageType } from './enums/MessageType';
import type { IContactMessage } from './interfaces/IContactMessage';
import type { IMessageContent } from './interfaces/IMessageContent';

export class ContactMessage extends BaseMessage implements IContactMessage {
  public static create(raw: IContactMessage): ContactMessage {
    return new ContactMessage(raw);
  }

  public type: MessageType.Contact;

  public content: IMessageContent<Contact>;

  public constructor(raw: IContactMessage) {
    super(raw);

    this.type = MessageType.Contact;
    this.content = {
      data: new Contact(raw.content.data),
    };
  }
}
