// import type { FileSource } from 'core/types';
import { BaseMessage } from './BaseMessage';
import { MessageType } from './enums/MessageType';
import type { IFadingPhotoMessage } from './interfaces/IFadingPhotoMessage';
import type { IFadingPhotoMessageAdditional } from './interfaces/IFadingPhotoMessageAdditional';
import type { IMessageContent } from './interfaces/IMessageContent';

export class FadingPhotoMessage extends BaseMessage implements IFadingPhotoMessage {
  public static create(raw: IFadingPhotoMessage): FadingPhotoMessage {
    return new FadingPhotoMessage(raw);
  }

  public type: MessageType.FadingPhoto;

  public content: IMessageContent<string> & IFadingPhotoMessageAdditional;

  public constructor(raw: IFadingPhotoMessage) {
    super(raw);

    this.type = MessageType.FadingPhoto;
    this.content = raw.content;
  }
}
