import { render } from "./CancelMessageButton.vue?vue&type=template&id=eb96c754"
import script from "./CancelMessageButton.ts?vue&type=script&lang=js"
export * from "./CancelMessageButton.ts?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "eb96c754"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('eb96c754', script)) {
    api.reload('eb96c754', script)
  }
  
  module.hot.accept("./CancelMessageButton.vue?vue&type=template&id=eb96c754", () => {
    api.rerender('eb96c754', render)
  })

}

script.__file = "src/views/Room/messages/CancelMessageButton/CancelMessageButton.vue"

export default script