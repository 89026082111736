import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { IUser } from 'api/users/models/user';
import { BtnType } from 'components/Btn';
import { useCurrency } from 'composables/currency';
import { useStore } from 'vuex';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { ChatsAction } from 'store/chats/enums';
import { AxiosError } from 'axios';
import { useApiErrors } from 'composables/apiErrors';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import { useFetching } from 'composables/fetching';
import RoomPrompt from '../RoomPrompt';

const RemoveChat = defineComponent({
  components: {
    RoomPrompt,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },
  emits: ['close', 'success', 'payment-required'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { formatCurrency } = useCurrency();
    const { showError } = useApiErrors();
    const nRemoves = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.conversation_deletions);
    const serviceInfo = useServiceSettings(ServiceNames.RemoveAllMessages, true);
    const formattedPrice = computed(() => formatCurrency(serviceInfo.value.price));

    function close() {
      emit('close');
    }

    function success() {
      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t('pages.room.removeChat.success'),
      });
      emit('success');
    }

    const { isFetching, fetch } = useFetching();

    async function removeAll() {
      if (!serviceInfo.value.isBought && !nRemoves.value) {
        emit('payment-required');
        return;
      }
      try {
        await fetch(store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.DeleteAllChat}`, props.user.id));
        success();
      } catch (e: AxiosError | any) {
        if (e?.response?.status === 402) {
          emit('payment-required');
        } else {
          showError(e);
        }
      }
    }

    async function removeSelf() {
      try {
        await fetch(store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.DeleteSelfChat}`, props.user.id));
        success();
      } catch (e: AxiosError | any) {
        showError(e);
      }
    }

    async function removeSelfAndBlock() {
      try {
        await fetch([
          store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.DeleteSelfChat}`, props.user.id),
          store.dispatch(`${USER_STORE_KEY}/${UsersAction.BlockUser}`, props.user.id),
        ]);
        success();
      } catch (e) {
        showError(e);
      }
    }

    return {
      isFetching,
      nRemoves,
      formattedPrice,
      serviceInfo,
      close,
      removeAll,
      removeSelf,
      removeSelfAndBlock,

      BtnType,
    };
  },
});

export default RemoveChat;
