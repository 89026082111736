import { ref, defineComponent } from 'vue';
import Prompt from 'components/Prompt';
import { useViewContact } from 'views/Room/eventBus/useViewContact';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { useApiErrors } from 'composables/apiErrors';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { CHATS_STORE_KEY, ChatsAction } from 'store/chats';
import { usePageLoader } from 'composables/pageLoader';

const SpendPackagePrompt = defineComponent({
  components: {
    Prompt,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    contactsExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success', 'start', 'end'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { showError } = useApiErrors();
    const { onOpenSpendPrompt, showUserContacts } = useViewContact();
    const store = useStore();

    let id: number | undefined;

    const model = ref(false);
    onOpenSpendPrompt((_id) => {
      model.value = true;
      id = _id;
    });

    const { startLoading, endLoading } = usePageLoader();

    async function openContacts() {
      if (!id) return;
      startLoading();
      emit('start');

      try {
        const user = await store.dispatch(`${USER_STORE_KEY}/${UsersAction.OpenContact}`, id);
        await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadMessagesByUserId}`, {
          more: false,
          clear: true,
          userId: props.userId,
        });
        emit('success', user);
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.room.spend1Contact.success'),
        });
        showUserContacts();
      } catch (e) {
        showError(e);
      } finally {
        id = undefined;
        endLoading();
        emit('end');
      }
    }
    return {
      model,
      openContacts,
    };
  },
});

export default SpendPackagePrompt;
