import { render } from "./SpendPackagePrompt.vue?vue&type=template&id=626b2ea0"
import script from "./SpendPackagePrompt.ts?vue&type=script&lang=ts"
export * from "./SpendPackagePrompt.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "626b2ea0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('626b2ea0', script)) {
    api.reload('626b2ea0', script)
  }
  
  module.hot.accept("./SpendPackagePrompt.vue?vue&type=template&id=626b2ea0", () => {
    api.rerender('626b2ea0', render)
  })

}

script.__file = "src/views/Room/components/SpendPackagePrompt/SpendPackagePrompt.vue"

export default script