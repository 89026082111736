<template>
  <room-prompt
    :show="show"
    @close="close"
  >
    <avatar
      class="remove-chat-prompt__avatar"
      :user="user"
    />
    <txt class="remove-chat-prompt__text">
      {{ $t('pages.room.removeChat.text') }}
    </txt>
    <txt class="remove-chat-prompt__sub-text">
      <template v-if="serviceInfo.isBought">
        {{ serviceInfo.boughtText }}
      </template>
      <template v-else-if="nRemoves">
        <b class="remove-chat-prompt__b">{{ $t('pages.room.removeChat.nRemoves', { n: nRemoves }) }}</b>
        {{ $t('pages.room.removeChat.description', { price: formattedPrice }) }}
      </template>

      <template v-else>
        {{ $t('pages.room.removeChat.noFreeDeletionsLeft') }}
      </template>
    </txt>
    <btn
      :type="BtnType.Text"
      w="100%"
      center
      :disabled="isFetching"
      @click="removeAll"
    >
      {{ $t('pages.room.removeChat.all', { name: user.name }) }}
    </btn>
    <btn
      :type="BtnType.Text"
      w="100%"
      center
      :disabled="isFetching"
      @click="removeSelf"
    >
      {{ $t('pages.room.removeChat.self') }}
    </btn>
    <btn
      :type="BtnType.Text"
      w="100%"
      center
      :disabled="isFetching"
      @click="removeSelfAndBlock"
    >
      {{ $t('pages.room.removeChat.removeAndBlock') }}
    </btn>
  </room-prompt>
</template>

<script lang="ts" src="./RemoveChat.ts" />
<style lang="scss" src="./removeChat.scss" />
