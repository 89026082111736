import { MessageType, TrueMessageType } from 'api/rooms/models/message';
import { Contact, ContactType } from 'api/users/models/contact';
import { IProfile, IUser, Sex, ThemeColor } from 'api/users/models/user';
import { ClaimType } from 'api/users/models/claims';
import { Message, SendMessageData } from 'api/chatsService';
import { IconName, IconType } from 'components/Icon';
import { ModalHeaderDirection } from 'components/Modal';
import { TxtType, TxtWeight } from 'components/Txt';
import { MainColor, Size } from 'core/styles';
import { FileSource, Nullable } from 'core/types';
import { USER_STORE_KEY, UsersAction, UsersGetter } from 'store/users';
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
  watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { ChatsAction, ChatsGetter } from 'store/chats/enums';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { formatTo24Hours } from 'core/date-time';
import { useUser } from 'composables/user';
import Prompt from 'components/Prompt';
import { copyToClipboard } from 'core/helpers';
import AddPrivatePhoto from 'views/Room/components/addPrivatePhoto';
import { isLovistaAdmin as checkIsLovistaAdmin } from 'core/helpers/isLovistaAdmin';
import MalePopup from 'components/PaidMessagesPopup/MalePopup';
import PaidRemoveChat from 'views/Room/components/PaidRemoveChat';
import { useFetching } from 'composables/fetching';
import { getOrderTaxiUrl } from 'views/Room/messages/Helpers';
import pusher, { IConversationDropped } from 'pusher';
import { MessageSendCallbacks } from 'store/chats/interfaces';
import { isEmptyString } from 'core/string';
import { RouteNames } from 'router/names';
import { useNotModerated } from 'modules/notModerated';
import { ModeratedActions } from 'modules/notModerated/types';
import { useFavorites } from 'composables/favorites';
import { useKeyboardScroll } from 'composables/keyboardScroll';
import { usePackageRequiredErrorHandler } from 'composables/packageRequiredErrorHandler';
import ContactList from 'components/ContactList';
import { useViewContact } from 'views/Room/eventBus/useViewContact';
import ChatMessage from './messages/ChatMessage';
import RoomAddContact from './components/RoomAddContact';
import BlockUser from './components/BlockUser';
import RemoveChat from './components/RemoveChat';
import ViewContactsAlert from './components/ViewContactsAlert';
import ViewContactsPopup from './components/ViewContactsPopup';
import NeedPackagePrompt from './components/NeedPackagePrompt';
import SpendPackagePrompt from './components/SpendPackagePrompt';
import UserContacts from './components/UserContacts';

const Room = defineComponent({
  name: RouteNames.Room,
  components: {
    Prompt,
    ChatMessage,
    BlockUser,
    AddPrivatePhoto,
    RoomAddContact,
    MalePopup,
    RemoveChat,
    PaidRemoveChat,
    ContactList,
    ViewContactsAlert,
    ViewContactsPopup,
    NeedPackagePrompt,
    SpendPackagePrompt,
    UserContacts,
  },

  setup() {
    const { t } = useI18n();

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { getUserStatus } = useUser();
    const { id } = route.params;
    const isAutoScrollEnabled = ref(true);
    let isScrollEventAutomated = false;
    const roomChatRef = ref();
    const roomChatAnchorRef = ref();

    const paidMessagesPopup = ref(false);
    const userId = parseInt(id as string, 10);

    const { showError } = usePackageRequiredErrorHandler(paidMessagesPopup, userId);

    const isBlockDialogShow = ref(false);
    const showBlockDialog = () => { isBlockDialogShow.value = true; };
    const closeBlockDialog = () => { isBlockDialogShow.value = false; };

    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const _photos: FileSource[] = [];
    const imageViewerPhotos = ref(_photos);
    const privatePhotos = computed(() => profile.value.private_photos || []);

    const messages = computed<(Message & { id: number })[]>(
      () => store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.MessagesByUserId}`](id));

    const user = ref<Nullable<IUser>>(null);

    const isUserLoading = ref(false);
    function loadUser() {
      isUserLoading.value = true;
      store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetUserById}`, { id: userId, withoutNotice: true }).then((usr) => {
        user.value = usr;
      }).catch((e) => showError(e)).finally(() => {
        isUserLoading.value = false;
      });
    }
    loadUser();
    function readMessages() {
      setTimeout(() => {
        store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.ReadMessagesByUserId}`, userId).catch((e) => showError(e));
      }, 1500);
    }

    async function unblockUser() {
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.UnblockUser}`, user.value?.id);
        loadUser();
      } catch (error) {
        toastEmitter.emit('toast', { type: ToastType.Error, message: t('pages.room.unblockUser.failure') });
      }
    }

    const isShowClaim = ref(false);

    const { isFavoriteUser } = useFavorites();
    const inFavorite = computed(() => isFavoriteUser(user?.value));

    const isShowDeleteChat = ref(false);
    const isShowPaidDeleteChat = ref(false);

    function closeDeleteChat() {
      isShowDeleteChat.value = false;
    }
    function onSuccessChatDeletion() {
      isShowDeleteChat.value = false;
      isShowPaidDeleteChat.value = false;
      router.push('/rooms');
    }

    function onChatDeletionPaymentRequired() {
      isShowDeleteChat.value = false;
      isShowPaidDeleteChat.value = true;
    }

    const isLovistaAdmin = computed(() => checkIsLovistaAdmin(user.value?.id));

    const canDeleteChat = computed(() => {
      if (isLovistaAdmin.value) return false;
      if (!messages.value.length) return false;
      return true;
    });

    const onChatDeletedEvent = () => {
      router.push('/rooms');
      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t('pages.room.userRemovedChat'),
      });
    };

    const { toggleFavorite } = useFavorites();
    const handleFavorite = () => {
      if (user.value) {
        toggleFavorite(user);
      }
    };

    const getContactHref = (contact: Contact): string => {
      if (contact.type === ContactType.Telegram) return `https://telegram.me/${contact.contact}`;
      if (contact.type === ContactType.WhatsApp) return `/wa?phone=${contact.contact}`;

      return `tel:${contact.contact}`;
    };

    const isScrolled = ref(false);
    function scrollToUnwatchedMessages() {
      if (messages.value && messages.value.length) {
        const unviewed = messages.value.find((item) =>
          item.recipient_id === profile.value.id && !item.read_at);
        if (unviewed) {
          scrollToMessageImmediate(unviewed.id);
        } else {
          scrollToMessageImmediate();
        }
        // setTimeout(() => { isScrolled.value = true; }, 150); - любые подобные таймауты это жесткие костыли. У всех разный интернет и разный компьютер и 150 мс может не хватить.
        isScrolled.value = true;
      }
    }
    const trackUnwatchMessages = () => {
      const messagesUnwatcher = watchEffect(() => {
        scrollToUnwatchedMessages();
        messagesUnwatcher();
      }, {
        flush: 'post',
      });
    };

    const isAttachMode = ref(false);
    const isAttachContactMode = ref(false);
    const attachContactRef = ref<HTMLElement>();
    const footerRef = ref<HTMLElement>();
    const input = ref('');
    const inputRef = ref<HTMLTextAreaElement>();

    const uploadFiles = ref<File[]>([]);

    const uploadedPreview = ref<string[]>([]);

    const isInputDirty = computed(() => !!input.value);

    const loadMessages = async (userId: any, more = false, clear = false) => {
      try {
        await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadMessagesByUserId}`, { userId, more, clear });
        if (!more) readMessages();
      } catch (e) {
        showError(e);
      }
    };

    const scheduleNewMessage = useGenerateChatsAction<{
      userId: string; data: SendMessageData, uploadPreview?: string[]; callbacks?: MessageSendCallbacks
    }>(ChatsAction.ScheduleNewMessage);

    const handleContact = async (contact: Contact) => {
      try {
        if (profile.value) {
          const data = await scheduleNewMessage({
            userId: id as string,
            data: {
              text: contact.contact,
              type: contact.type,
              geolocation: contact.geolocation,
            },
            callbacks: {
              onError: (_, __, error) => {
                showError(error);
              },
            },
          });
          scrollToMessage(data.id);
        }

        isAttachMode.value = false;
        isAttachContactMode.value = false;
      } catch (e) {
        showError(e);
      }
    };

    const { checkModerate } = useNotModerated();

    const handleSend = async () => {
      if (!checkModerate(ModeratedActions.SendMessage)) return;

      if (isEmptyString(input.value) && !uploadFiles.value.length) {
        inputRef.value?.focus();
        return;
      }
      let promise;

      const savedInput = input.value;
      if (!isEmptyString(savedInput)) {
        input.value = '';
        nextTick(() => autoGrow());
      }

      isAutoScrollEnabled.value = true;

      if (uploadFiles.value.length) {
        promise = scheduleNewMessage({
          userId: id as string,
          data: {
            type: 'photo',
            photo: uploadFiles.value,
          },
          uploadPreview: uploadedPreview.value,
          callbacks: {
            onError(messageId: string, recipientId: number, error: any) {
              showError(error);
            },
          },
        });

        uploadFiles.value = [];
        uploadedPreview.value = [];
        nextTick(() => scrollToMessage());
      }
      if (promise) await promise;
      if (!isEmptyString(savedInput)) {
        promise = scheduleNewMessage({
          userId: id as string,
          data: {
            type: 'text',
            text: savedInput,
          },
          callbacks: {
            onError: (_, __, error) => {
              showError(error);
            },
          },
        });
        nextTick(() => scrollToMessage());
        await promise;
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!e.metaKey && !e.ctrlKey && !e.altKey) return;

      handleSend();
    };

    const claims = Object.values(ClaimType);

    const handleUploadPhoto = (e: InputEvent) => {
      const target = e.target as HTMLInputElement;
      if (target.files) {
        uploadFiles.value = [];
        uploadedPreview.value = [];

        uploadFiles.value = Array.from(target.files).slice(0, 10);
        uploadedPreview.value = uploadFiles.value.slice().map((file) => URL.createObjectURL(file));
      }

      isAttachMode.value = false;
    };

    const handleRemoveImage = (i: number) => {
      if (uploadFiles.value) {
        uploadFiles.value.splice(i, 1);
        uploadedPreview.value.splice(i, 1);
      }
    };

    const isAddContactsShow = ref(false);
    const isAddPrivatePhotoShow = ref(false);
    const isPhotoViewerOpen = ref(false);

    function openPrivatePhotos() {
      if (privatePhotos.value.length > 0) {
        isPhotoViewerOpen.value = true;
      } else {
        isAddPrivatePhotoShow.value = true;
      }
    }
    const {
      isFetching: isPrivatePhotoFetching,
      startFetching: startPrivatePhotoFetching,
      endFetching: endPrivatePhotoFetching,
    } = useFetching();
    const handleSubmitPrivatePhoto = async () => {
      try {
        const privatePhotos = profile.value.private_photos;
        startPrivatePhotoFetching();
        const data = await scheduleNewMessage({
          userId: id as string,
          data: {
            type: 'private_photo',
            text: privatePhotos.map((p) => p.path).join('\n'),
          },
          callbacks: {
            onSuccess: endPrivatePhotoFetching,
            onError: (_, __, error) => {
              endPrivatePhotoFetching();
              showError(error);
            },
          },
        });

        isPhotoViewerOpen.value = false;
        isAttachMode.value = false;
        scrollToMessage(data.id);
      } catch (e) {
        showError(e);
      }
    };

    function handleTap(e: TouchEvent) {
      const target = e.target as HTMLElement;

      if (isAttachContactMode.value && !(attachContactRef.value?.contains(target))) {
        e.preventDefault();
        isAttachMode.value = false;
        isAttachContactMode.value = false;
      }
      if (isAttachMode.value
          && !isAttachContactMode.value
          && !isPhotoViewerOpen.value
          && !(footerRef.value?.contains(target))) {
        e.preventDefault();
        isAttachMode.value = false;
        isAttachContactMode.value = false;
      }
    }

    async function handleClaim(type: ClaimType) {
      if (!user.value) return;

      const data = {
        userId: user.value.id,
        type,
      };
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.PutClaim}`, data);

        isShowClaim.value = false;

        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.claimSuccess'),
        });
      } catch (e) {
        showError(e);
      }
    }

    function scrollToMessage(id?: number) {
      if (!isAutoScrollEnabled.value) return;
      nextTick(() => {
        const selector = id ? `#message-${id}` : '#room-chat-messages-end';
        isScrollEventAutomated = true;
        const el = document.querySelector(selector);
        if (el) {
          (el as HTMLElement).scrollIntoView({ block: 'center' });
        }
        setTimeout(() => { isScrollEventAutomated = false; }, 50);
      });
    }

    function scrollToMessageImmediate(id?: string) {
      nextTick(() => {
        const msgEl = document.querySelector(id ? `#message-${id}` : '#room-chat-messages-end') as HTMLDivElement;
        if (!msgEl) return;
        roomChatRef.value.scrollTo({
          top: msgEl?.offsetTop,
          left: 0,
          behavior: 'instant',
        });
      });
    }

    const chatHasNextPage = computed(() => store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.ChatHasNextPage}`](userId));
    let lastMessageId: string | undefined;
    let oldMessageCount: number | undefined;
    const { fetch, isFetching } = useFetching();
    watch(messages, (value) => {
      if (oldMessageCount && value.length > oldMessageCount && roomChatRef.value && lastMessageId) {
        scrollToMessageImmediate(lastMessageId);
      }
    });

    const observer = new IntersectionObserver(onIntersect, {
      rootMargin: '100px',
      threshold: 0,
    });
    watch(user, (v) => {
      if (v) {
        nextTick(() => observer.observe(roomChatAnchorRef.value));
      }
    });
    onBeforeUnmount(() => {
      if (!roomChatAnchorRef.value) return;
      observer.unobserve(roomChatAnchorRef.value);
    });
    function onIntersect(entry: IntersectionObserverEntry[]) {
      if (chatHasNextPage.value && !isFetching.value && entry[0].isIntersecting) {
        fetch(loadMessages(userId, true));
        lastMessageId = messages.value[0]?.id;
        oldMessageCount = messages.value.length;
      }
    }

    const handleScroll = (event: Event) => {
      /* Disable auto scrolling to the new messages if user scrolls chat manually
         Re-enable auto scrolling when user just scrolled chat to the last message
         We asssume that auto scrolling can be triggered only via scrollToMessage function call
      */
      const el = event.target;
      if (!(el instanceof Element)) return;
      if (el.scrollHeight - el.scrollTop - el.clientHeight < 80) {
        isAutoScrollEnabled.value = true;
      } else if (!isScrollEventAutomated) {
        isAutoScrollEnabled.value = false;
      }
    };

    const formatTo24 = (dateStr: string) => formatTo24Hours(new Date(dateStr));

    const checkIsContactMessage = (messageType: string) => ([
      TrueMessageType.Telegram,
      TrueMessageType.Phone,
      TrueMessageType.Whatsapp,
      TrueMessageType.Address,
    ] as string[]).includes(messageType);

    const isMessagingAvailable = (): boolean => {
      if (!user.value) return false;
      if (user.value.blocked_at) return false;
      if (user.value.deleted_at) return false;
      return true;
    };

    async function copyAddress(address: string) {
      await copyToClipboard(address);
      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t('pages.room.addressCopied'),
      });
    }

    const activeThemeColor = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--theme-color-name') as ThemeColor
        || ThemeColor.Lavender;

    const isInterlocutorTyping = ref(false);
    let stopTypingEventTimeout: any = null;
    let isTypingEventTimeout: any = null;

    function autoGrow() {
      if (!inputRef.value) return;
      inputRef.value.style.height = 'auto';
      inputRef.value.style.height = `${inputRef.value.scrollHeight}px`;
    }

    const handleTyping = () => {
      if (stopTypingEventTimeout) clearTimeout(stopTypingEventTimeout);
      stopTypingEventTimeout = setTimeout(() => {
        pusher.typingPusher.triggerClientStoppedTyping();
      }, 3000);
      if (!isTypingEventTimeout) {
        pusher.typingPusher.triggerClientIsTyping();
        isTypingEventTimeout = setTimeout(() => {
          isTypingEventTimeout = null;
        }, 2000);
      }
    };

    function handleAttachContact() {
      if (profile.value?.contacts && profile.value.contacts.length > 0) {
        isAttachContactMode.value = true;
      } else {
        isAddContactsShow.value = true;
      }
    }

    useKeyboardScroll(inputRef);

    onMounted(() => {
      document.getElementById('app')?.style.setProperty('position', 'unset');
    });

    onBeforeUnmount(() => {
      document.getElementById('app')?.style.removeProperty('position');
    });

    let unsubscribeArray: Array<() => void> = [];
    onMounted(async () => {
      isAutoScrollEnabled.value = true;
      isScrollEventAutomated = true;
      const unwatchUserLoader = watch(user, (value) => {
        if (value) {
          // @todo возможно не стоит показывать старые сообщения пока не подгрузятся новые
          loadMessages(userId).then(() => trackUnwatchMessages());
          scrollToUnwatchedMessages();
          unwatchUserLoader();
        }
      });

      if (!store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.ChatByUserId}`](id)) {
        try {
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChatByUserId}`, id);
        } catch (e) {
          showError(e);
        }
      }
      const chatConversation = store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.ChatByUserId}`](id);
      const dialogId = chatConversation.id;

      unsubscribeArray.push(pusher.roomPusher.subscribeToMessageReaded(async () => {
        await loadMessages(id);
        scrollToMessage();
      }));
      unsubscribeArray.push(pusher.roomPusher.subscribeToMessageCreated(() => {
        nextTick(() => {
          scrollToMessage();
          readMessages();
        });
      }));
      unsubscribeArray.push(pusher.roomPusher.subscribeToConversationDropped(async (data: IConversationDropped) => {
        if (data.userId === profile.value.id) {
          onChatDeletedEvent();
        }
      }));

      pusher.typingPusher.init(dialogId);
      pusher.typingPusher.subscribeToClientIsTyping(() => {
        isInterlocutorTyping.value = true;
        scrollToMessage();
      });
      pusher.typingPusher.subscribeToClientStoppedTyping(() => {
        isInterlocutorTyping.value = false;
      });
    });

    onUnmounted(() => {
      pusher.typingPusher.unsubscribe();
      unsubscribeArray.forEach((fn) => {
        fn();
      });
      unsubscribeArray = [];
    });

    function toUserProfile() {
      if (!isLovistaAdmin.value && !user.value?.deleted_at && !user.value?.blocked_at) {
        router.push({ name: 'user', params: { id: userId } });
      }
    }

    function handleTextareaFocus() {
      window?.scrollTo(0, 0);
      isAttachMode.value = false;
    }

    const spendPackageFetching = ref(false);
    function onSpendPackageStartFetch() {
      spendPackageFetching.value = true;
    }
    function onSpendPackageEndFetch() {
      spendPackageFetching.value = false;
    }

    const viewPopupFetching = ref(false);
    function onViewPopupStartFetch() {
      viewPopupFetching.value = true;
    }
    function onViewPopupEndFetch() {
      viewPopupFetching.value = false;
    }

    const { showPrompt, showUserContacts, showSpendPrompt, showPopup } = useViewContact();
    function viewContacts() {
      if (spendPackageFetching.value || viewPopupFetching.value || isUserLoading.value) return;

      if (
        !store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]?.length
        && !profile.value.available_contacts_open
        && !profile.value.available_see_contacts
        && !user.value?.contacts_is_open
      ) {
        showPrompt();
        return;
      }

      if (profile.value.available_see_contacts || user.value?.contacts_is_open) {
        showUserContacts();
        return;
      }

      if (profile.value.available_contacts_open && !user.value?.contacts_is_open) {
        showSpendPrompt(userId);
        return;
      }

      showPopup();
    }

    function handleSpendPackageSuccess(_user: IUser) {
      user.value = _user;
    }

    return {
      getUserStatus,
      isLovistaAdmin,

      isBlockDialogShow,
      roomChatAnchorRef,
      showBlockDialog,
      closeBlockDialog,
      toUserProfile,
      handleTextareaFocus,
      viewContacts,

      formatTo24,
      checkIsContactMessage,
      handleSubmitPrivatePhoto,
      isPhotoViewerOpen,

      isScrolled,
      handleScroll,

      handleTyping,
      isInterlocutorTyping,
      isMessagingAvailable,
      isAddPrivatePhotoShow,
      isAddContactsShow,
      isAttachMode,
      isAttachContactMode,
      attachContactRef,
      footerRef,
      input,
      inputRef,
      isInputDirty,
      profile,
      imageViewerPhotos,
      privatePhotos,
      openPrivatePhotos,
      user,
      messages,

      paidMessagesPopup,

      inFavorite,
      isShowClaim,

      claims,
      uploadedPreview,

      loadUser,
      unblockUser,

      roomChatRef,
      isShowDeleteChat,
      closeDeleteChat,
      onSuccessChatDeletion,
      isShowPaidDeleteChat,
      userId,
      onChatDeletionPaymentRequired,
      canDeleteChat,
      isPrivatePhotoFetching,

      handleAttachContact,
      handleTap,
      handleFavorite,
      handleRemoveImage,
      handleUploadPhoto,

      getContactHref,

      handleContact,
      handleSend,
      handleKeyDown,
      handleClaim,
      autoGrow,

      copyAddress,
      getOrderTaxiUrl,
      handleSpendPackageSuccess,
      onSpendPackageStartFetch,
      onSpendPackageEndFetch,
      onViewPopupStartFetch,
      onViewPopupEndFetch,

      activeThemeColor,

      IconName,
      IconType,
      Size,
      TxtType,
      TxtWeight,
      MainColor,
      MessageType,
      TrueMessageType,
      ContactType,
      Sex,
      ModalHeaderDirection,
    };
  },
});

export default Room;
