import { computed, defineComponent, ref } from 'vue';
import BottomModal from 'components/BottomModal';
import { MainColor, Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import AddPhotos from 'views/Profile/components/AddPhotos';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';

const AddPrivatePhoto = defineComponent({
  components: {
    BottomModal,
    AddPhotos,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'submit'],
  setup(props, { emit }) {
    const store = useStore();
    const isShow = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    const photos = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.private_photos);
    const isPhotoLoaded = ref(false);

    function close() {
      isShow.value = false;
    }

    function onLoad() {
      isPhotoLoaded.value = true;
    }

    function onClick() {
      emit('submit');
      close();
    }

    return {
      isShow,
      close,

      isPhotoLoaded,
      photos,
      onLoad,
      onClick,

      MainColor,
      IconType,
      IconName,
      Size,
    };
  },
});

export default AddPrivatePhoto;
