import { render } from "./ButtonMessage.vue?vue&type=template&id=33b6007c"
import script from "./ButtonMessage.ts?vue&type=script&lang=js"
export * from "./ButtonMessage.ts?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "33b6007c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('33b6007c', script)) {
    api.reload('33b6007c', script)
  }
  
  module.hot.accept("./ButtonMessage.vue?vue&type=template&id=33b6007c", () => {
    api.rerender('33b6007c', render)
  })

}

script.__file = "src/views/Room/messages/ButtonMessage/ButtonMessage.vue"

export default script