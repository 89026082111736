import { render } from "./Message.vue?vue&type=template&id=8d77a812"
import script from "./Message.ts?vue&type=script&lang=ts"
export * from "./Message.ts?vue&type=script&lang=ts"

import "./message.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "8d77a812"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('8d77a812', script)) {
    api.reload('8d77a812', script)
  }
  
  module.hot.accept("./Message.vue?vue&type=template&id=8d77a812", () => {
    api.rerender('8d77a812', render)
  })

}

script.__file = "src/views/Rooms/components/Message/Message.vue"

export default script