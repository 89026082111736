import { defineComponent, PropType, ref } from 'vue';
import ContactList from 'components/ContactList';
import { IContact } from 'api/users/models/contact';
import { useViewContact } from 'views/Room/eventBus/useViewContact';

const UserContacts = defineComponent({
  components: {
    ContactList,
  },
  props: {
    contacts: {
      type: Array as PropType<IContact[]>,
      required: true,
    },
  },
  setup() {
    const { onShowUserContacts } = useViewContact();

    const model = ref(false);

    onShowUserContacts(() => {
      model.value = true;
    });

    function onClickOutside() {
      if (!model.value) return;

      model.value = false;
    }

    return {
      model,
      onClickOutside,
    };
  },
});

export default UserContacts;
