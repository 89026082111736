<template>
  <main-layout v-show="!isSearch">
    <list-layout
      :title="$t('pages.rooms.title')"
      class="rooms"
    >
      <template #info>
        <stories v-model:isStoriesOpen="isStoriesOpen" />
        <to-success-users />
      </template>

      <template #body>
        <div
          v-if="!Object.keys(chats).length"
          v-show="!isStoriesOpen"
          class="rooms-empty"
        >
          <div class="rooms-empty__icon-background">
            <icon
              :type="IconType.Contour"
              :name="IconName.Email "
              :size="Size.Xxl"
              :color="MainColor.Grey4"
            />
          </div>
          <p class="rooms-empty__text">
            {{ $t('pages.rooms.empty.' + profile.gender) }}
          </p>
          <router-link to="/catalog">
            <btn class="rooms-empty__btn">Перейти к анкетам</btn>
          </router-link>
        </div>

        <div
          v-for="item in chats"
          v-show="!isStoriesOpen"
          :key="getUser(item).id"
          class="rooms-item__wrapper"
          @click="onClickConversation(item)"
        >
          <blocked-user
            v-if="getUser(item).deleted_at || getUser(item).blocked_at"
            :message="item"
          />
          <message
            v-else
            :message="item"
          />
        </div>
      </template>
    </list-layout>
  </main-layout>

  <div
    v-show="isSearch"
    class="search"
  >
    <div class="search__close-panel">
      <icon-btn
        :size="Size.M"
        class="search__close-btn"
        @click="isSearch = false"
      >
        <icon
          :type="IconType.Contour"
          :name="IconName.Crosses"
          :size="Size.Xs"
        />
      </icon-btn>
      <txt
        :weight="TxtWeight.Bold"
        :type="TxtType.Heading4"
      >
        {{ $t('pages.rooms.search') }}
      </txt>
    </div>

    <div class="search__panel">
      <textfield
        v-model="search"
        :style="TextfieldStyle.Secondary"
        cleanable
        placeholder="Поиск"
        class="search__field"
        :ri="{
          type: IconType.Contour,
          name: IconName.MagnifyingGlass,
        }"
      />
    </div>

    <empty
      v-if="findedUsers.length === 0 && findedMessages.length === 0"
      action-text-path="pages.rooms.resetSearch"
      @action-click="closeSearch"
    />

    <div
      v-for="user in findedUsers"
      :key="user.id"
      class="search-user"
      @click="$router.push({ name: 'user', params: { id: user.id } })"
    >
      <avatar
        :has-online-marker="user.is_online"
        :user="user"
        :size="Size.Xs"
        class="search-user__avatar"
      />

      <txt
        tag="div"
        :weight="TxtWeight.Bold"
        class="search-user__name"
      >
        {{ user.name }}, {{ `${user.age} ${$tc('user.age', user.age)}` }}
      </txt>
    </div>

    <div
      v-for="item in findedMessages"
      :key="item.room.id"
      class="rooms-item"
      @click="$router.push(item.room.getHref())"
    >
      <avatar
        :has-plus="false"
        :has-online-marker="item.user.is_online"
        :user="item.user"
        class="rooms-item__avatar"
      />

      <div class="rooms-item__content">
        <txt
          tag="div"
          :weight="TxtWeight.Bold"
          class="rooms-item__title"
        >
          {{ item.user.name }}, {{ `${item.user.age} ${$tc('user.age', item.user.age)}` }}
        </txt>

        <txt
          v-if="item.room.getLastMessage()"
          tag="div"
          :type="TxtType.SmallText"
          class="rooms-item__message"
        >
          {{ item.room.getLastMessage().content.data.type
            ? item.room.getLastMessage().content.data.contact
            : item.room.getLastMessage().content.data }}
        </txt>
      </div>

      <div
        v-if="item.room.getLastMessage()"
        class="rooms-item__info"
      >
        <icon
          v-if="item.room.getLastMessage().status === MessageStatus.Viewed"
          :type="IconType.Fill"
          :name="IconName.DoubleMark"
          :color="MainColor.Green"
          :size="Size.Xs"
          class="rooms-item__status"
        />

        <txt
          :type="TxtType.SmallText"
          class="rooms-item__datetime"
        >
          {{ item.room.getLastMessage().publishedAt.format('HH:MM') }}
        </txt>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./Rooms.ts" />
<style lang="scss" src="./rooms.scss" />
