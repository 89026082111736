<template>
  <div v-if="show" class="bottom-modal">
    <div class="bottom-modal_header">
      <h4 class="bottom-modal_title">
        {{ title }}
      </h4>
      <icon-btn
        class="bottom-modal_close-btn"
        :size="Size.M"
        @click="close"
      >
        <icon
          :size="Size.Xs"
          :type="IconType.Contour"
          :name="IconName.Crosses"
        />
      </icon-btn>
    </div>
    <div class="bottom-modal_content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" src="./BottomModal.ts" />
<style lang="scss" src="./bottomModal.scss" />
