<template>
  <div class="app-contact-list">
    <template v-if="contacts.length">
      <txt
        v-for="contact in contacts"
        tag="div"
        :type="TxtType.SmallText"
        :weight="TxtWeight.Medium"
        :class="`app-contact-list__button app-contact-list__button-${contact.type}`"
        @click="handleContactClick(contact)"
      >
        <div>{{ contact.contact }}</div>

        <icon
          :type="IconType.Contour"
          :name="getContactIcon(contact.type)"
          :color="MainColor.White"
          :size="Size.S"
        />
      </txt>
    </template>

    <div
      v-else
      class="text-center"
    >
      {{ $t('components.contactList.empty') }}
    </div>
  </div>
</template>

<script lang="ts" src="./ContactList.ts" />
<style lang="scss" src="./contactList.scss" />
