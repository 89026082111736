<template>
  <bottom-modal
    class="add-private-photo"
    :show="isShow"
    :title="$t('pages.room.addPrivatePhotos.title')"
    @close="close"
  >
    <p>{{ $t('pages.room.addPrivatePhotos.content') }}</p>
    <add-photos
      class="add-private-photo__add-photo"
      :photos="photos"
      private
      @load="onLoad"
    />
    <btn
      v-if="isPhotoLoaded"
      w="100%"
      class="add-private-photo__button"
      @click="onClick"
    >
      {{ $t('pages.room.addPrivatePhotos.submit') }}
    </btn>
  </bottom-modal>
</template>

<script lang="ts" src="./AddPrivatePhoto.ts" />
<style lang="scss" src="./addPrivatePhoto.scss" />
