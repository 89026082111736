import type { Id } from 'core/types';
import type { Dayjs } from 'dayjs';
import { MessageStatus } from './enums/MessageStatus';
import { MessageType } from './enums/MessageType';
import type { IBaseMessage } from './interfaces/IBaseMessage';
import type { IMessageContent } from './interfaces/IMessageContent';

export class BaseMessage implements IBaseMessage {
  public static create(raw: IBaseMessage): BaseMessage {
    return new BaseMessage(raw);
  }

  public id: Id;
  public authorId: Id;
  public type: MessageType;
  public status: MessageStatus;
  public publishedAt: Dayjs;
  public isDistributionReply: boolean;

  public content: IMessageContent<unknown>;

  public constructor(raw: IBaseMessage) {
    this.id = raw.id;
    this.authorId = raw.authorId;
    this.type = raw.type;
    this.status = raw.status;
    this.publishedAt = raw.publishedAt;
    this.isDistributionReply = raw.isDistributionReply ?? false;
    this.content = raw.content;
  }
}
