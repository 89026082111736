import { defineComponent } from 'vue';
import { IconType } from 'components/Icon';
import { Size } from 'core/styles';

const MessageStatus = defineComponent({
  props: {
    time: {
      type: [String, Number],
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      IconType,
      Size,
    };
  },
});

export default MessageStatus;
