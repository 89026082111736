import { computed, defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { Conversation } from 'api/chatsService';
import { isLovistaAdmin } from 'core/helpers/isLovistaAdmin';
import { useI18n } from 'vue-i18n';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { formatToChat } from 'core/date-time';

const BlockedUser = defineComponent({
  props: {
    message: {
      type: Object as PropType<Conversation>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();

    const user = computed(() => props.message.opposite_user);

    const formatToChatTime = (dateStr: string) => formatToChat(new Date(dateStr));

    const fullName = computed(() => {
      const { name, age, id, deleted_at, blocked_at } = user.value;
      if (isLovistaAdmin(id) || deleted_at || blocked_at) return name;
      return `${name}, ${age} ${t('user.age', age)}`;
    });

    return {
      fullName,
      user,
      formatToChatTime,
    };
  },

  data() {
    return {
      IconName,
      IconType,
      Size,
      MainColor,
      TxtWeight,
      TxtType,
    };
  },
});

export default BlockedUser;
