<template>
  <div
    class="room-block-message"
  >
    <div class="room-block-message__avatar">
      <icon
        :name="IconName.Block"
        :type="IconType.Big"
        :color="MainColor.Grey5"
        />
    </div>
    <div class="room-block-message__content">
      <txt
        tag="div"
        :weight="TxtWeight.Bold"
        class="rooms-item__title"
      >
        {{ fullName }}
      </txt>

      <txt
        tag="div"
        :type="TxtType.SmallText"
        :weight="TxtWeight.Bold"
        class="rooms-item__message"
      >
        <span v-if="user.deleted_at">
          {{ $t('pages.rooms.blockedMessage.deleted') }}
        </span>
        <span v-else>
          {{ $t('pages.rooms.blockedMessage.blocked') }}
        </span>
      </txt>
    </div>
  </div>
</template>

<script lang="ts" src="./BlockedUser.ts" />
<style lang="scss" src="./blockedUser.scss" />
