import { defineComponent } from 'vue';
import { TxtType, TxtWeight } from 'components/Txt';
import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { PropType } from '@vue/runtime-core';
import { ContactType, IContact } from 'api/users/models/contact';

export default defineComponent({
  props: {
    contacts: {
      type: Array as PropType<IContact[]>,
      required: true,
    },
  },
  emits: ['contact-click'],
  setup(props, { emit }) {
    function handleContactClick(contact: IContact) {
      emit('contact-click', contact);
    }

    const getContactIcon = (type: ContactType): IconName => {
      if (type === ContactType.Telegram) return IconName.Telegram;
      if (type === ContactType.WhatsApp) return IconName.Whatsapp;
      if (type === ContactType.Address) return IconName.Pin;

      return IconName.Phone;
    };
    return {
      handleContactClick,
      getContactIcon,

      TxtType,
      TxtWeight,
      IconType,
      MainColor,
      Size,
    };
  },
});
