import { computed, defineComponent, ref } from 'vue';
import BottomModal from 'components/BottomModal';
import { MainColor, Size } from 'core/styles';
import { IconName, IconType } from 'components/Icon';
import AddContacts from 'views/Profile/components/AddContacts';
import { IContact } from 'api/users/models/contact';
import { Nullable } from 'core/types';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { useStore } from 'vuex';
import { useApiErrors } from 'composables/apiErrors';

const RoomAddContact = defineComponent({
  components: {
    BottomModal,
    AddContacts,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'submit'],
  setup(props, { emit }) {
    const store = useStore();
    const { showError } = useApiErrors();
    const isShow = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });
    const validContacts = ref<Nullable<IContact[]>>(null);
    const isBtnDisabled = computed(() => !validContacts.value || !validContacts.value.length);

    function close() {
      isShow.value = false;
    }

    async function onClick() {
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.AddContacts}`, validContacts.value);
        emit('submit');
        close();
      } catch (error) {
        showError(error);
      }
    }

    return {
      isShow,
      close,
      onClick,
      validContacts,
      isBtnDisabled,

      MainColor,
      IconType,
      IconName,
      Size,
    };
  },
});

export default RoomAddContact;
