<template>
  <div
    v-if="isStory"
    class="plain-text-message-story-reply"
  >
    <span class="plain-text-message-story-reply__tag">
      {{ $t('pages.room.storyRefer') }}
    </span>
    <icon
      :name="IconName.BackArrow"
      :type="IconType.Fill"
      :size="Size.Xs"
    />
  </div>
  {{ message.content.text }}
</template>

<script lang="ts" src="./PlainTextMessage.ts"></script>
<style lang="scss" src="./plainTextMessage.scss" />
