import { defineComponent } from 'vue';
import { PropType } from '@vue/runtime-core';
import { IUser } from 'api/users/models/user';
import { BtnType } from 'components/Btn';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { useFetching } from 'composables/fetching';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { ChatsAction } from 'store/chats/enums';
import { useApiErrors } from 'composables/apiErrors';
import RoomPrompt from 'views/Room/components/RoomPrompt';

const BlockUser = defineComponent({
  components: {
    RoomPrompt,
  },
  props: {
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'success'],
  setup(props, { emit }) {
    const store = useStore();
    const { showError } = useApiErrors();

    const { isFetching, fetch } = useFetching();

    async function blockUser() {
      try {
        await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.BlockUser}`, props.user.id));
        emit('success');
        await fetch(store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.LoadChats}`));
      } catch (error) {
        showError(error);
      } finally {
        close();
      }
    }

    function close() {
      emit('close');
    }

    return {
      isFetching,

      blockUser,
      close,
    };
  },

  data() {
    return {
      BtnType,
    };
  },
});

export default BlockUser;
