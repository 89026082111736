<template>
  <div>
    <transition name="message-status">
      <icon
        v-if="iconName"
        :name="iconName"
        :type="IconType.Fill"
        :size="Size.Xs"
      />
    </transition>
    <div>{{ time }}</div>
  </div>
</template>

<script lang="ts" src="./MessageStatus.ts" />
<style lang="scss">
.message-status {
  &-enter-active,
  &-leave-active {
    transition: 500ms ease;
  }
  &-enter-from,
  &-leave-to {
    opacity: 0;
  }
}
</style>
