import { computed, defineComponent } from 'vue';
import Prompt from 'components/Prompt';
import { useCurrency } from 'composables/currency';
import { useStore } from 'vuex';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { ChatsAction } from 'store/chats/enums';
import { useApiErrors } from 'composables/apiErrors';
import { TxtType } from 'components/Txt';
import { PropType } from '@vue/runtime-core';
import { IUser } from 'api/users/models/user';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';

const PaidRemoveChat = defineComponent({
  components: {
    Prompt,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'success'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const { formatCurrency } = useCurrency();
    const { showError } = useApiErrors();
    const serviceInfo = useServiceSettings(ServiceNames.RemoveAllMessages, true);
    const formattedPrice = computed(() => formatCurrency(serviceInfo.value.price));

    async function removeChat() {
      try {
        await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.PurchaseDeletion}`);
        await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.DeleteAllChat}`, props.user.id);
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.room.paidRemoveChat.success'),
        });
        emit('success');
      } catch (e) {
        showError(e);
        close();
      }
    }

    function close() {
      emit('update:modelValue', false);
    }

    return {
      formattedPrice,
      close,
      removeChat,

      TxtType,
    };
  },
});

export default PaidRemoveChat;
