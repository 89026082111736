import { defineComponent } from '@vue/runtime-core';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';

const PlainTextMessage = defineComponent({
  props: ['message', 'isSelf', 'isStory'],
  setup() {
    return {
      IconName,
      IconType,
      Size,
    };
  },
});

export default PlainTextMessage;
