<template>
  <teleport to="#modals">
    <transition name="room-prompt">
      <div
        v-if="show"
        class="room-prompt"
        @click.self="close"
      >
        <div
          class="room-prompt__wrapper room-prompt__wrapper--main"
          :class="classes"
        >
          <slot />
        </div>
        <div class="room-prompt__wrapper">
          <btn
            class="room-prompt__reject-button"
            :type="BtnType.Text"
            w="100%"
            center
            @click="close"
          >
            {{ $t('pages.room.cancel') }}
          </btn>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts" src="./RoomPrompt.ts" />
<style lang="scss" src="./roomPrompt.scss" />
