import { defineComponent } from '@vue/runtime-core';
import { IconName, IconType } from 'components/Icon';
import Prompt from 'components/Prompt';
import { MainColor, Size } from 'core/styles';
import { FileSource } from 'core/types';
import { ChatsAction } from 'store/chats/enums';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { ref } from 'vue';
import { useApiErrors } from 'composables/apiErrors';

const PrivatePhotoMessage = defineComponent({
  components: {
    Prompt,
  },

  props: ['message', 'isSelf'],

  setup() {
    const { showError } = useApiErrors();
    const viewerImages = ref<FileSource[]>([]);
    const isShowViewer = ref(false);
    const viewerImagesActiveIndex = ref(0);
    const deleteMessage = useGenerateChatsAction<{ userId: string; messageId: number }>(ChatsAction.DeleteMessage);

    const handleShowPhoto = (sources: string[]) => {
      if (sources.length) {
        viewerImages.value = sources.map((path, i) => ({
          id: i + 1,
          path,
          thumbnail: path,
        }));
        isShowViewer.value = true;
      }
    };

    const closeAccess = (userId: string, messageId: number) => {
      deleteMessage({
        userId,
        messageId,
      }).catch((e) => showError(e));
    };

    const handleCloseViewer = () => {
      viewerImages.value = [];
      isShowViewer.value = false;
      viewerImagesActiveIndex.value = 0;
    };

    return {
      isShowViewer,
      viewerImages,
      viewerImagesActiveIndex,
      handleCloseViewer,
      IconName,
      IconType,
      Size,
      MainColor,
      handleShowPhoto,
      closeAccess,
    };
  },
});

export default PrivatePhotoMessage;
