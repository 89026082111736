<template>
  <div
    class="room-message"
    :class="{ 'room-message--unread': message.unread_messages > 0 && !selfMessage }"
  >
    <avatar
      :has-plus="false"
      :has-online-marker="user.is_online"
      :user="user"
      class="room-message__avatar"
    />
    <div class="room-message__row">
      <div class="room-message__content">
        <txt
          tag="div"
          :weight="TxtWeight.Bold"
          class="room-message__message-title"
        >
          {{ fullName }}
          <template v-if="isLovistaAdmin(user.id)">
            <icon
              :type="IconType.Fill"
              :name="IconName.Verified"
              :size="Size.Xs"
            />
          </template>
        </txt>

        <txt
          v-if="message"
          tag="div"
          :type="TxtType.SmallText"
          class="room-message__message"
        >
          <span v-if="selfMessage">
            {{ $t('pages.rooms.message.you') }}:
          </span>
          <span v-if="message.last_message.type === 'photo'">
            {{ $t('pages.rooms.message.photo') }}
          </span>
          <span v-else-if="message.last_message.type === 'private_photo'">
            {{ $t('pages.rooms.message.privatePhoto') }}
          </span>
          <span v-else-if="message.last_message.type === 'button'">
            {{ message.last_message.content.title }}
          </span>
          <span v-else>
            {{ message.last_message.content[message.last_message.type] }}
          </span>
        </txt>
      </div>

      <div class="room-message__status">
        <txt
          :type="TxtType.SmallText"
          class="room-message__message-time"
        >
          {{ formatToChatTime(message.last_message.created_at) }}
        </txt>

        <icon
          v-if="statusIconName"
          class="room-message__icon"
          :type="IconType.Fill"
          :name="statusIconName"
          :size="Size.S"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./Message.ts" />
<style lang="scss" src="./message.scss" />
