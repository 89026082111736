import { defineComponent, toRefs } from '@vue/runtime-core';
import { ChatsAction } from 'store/chats/enums';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { useApiErrors } from 'composables/apiErrors';

const RetryMessageButton = defineComponent({
    props: ['userId', 'messageId'],

    setup(props) {
      const { showError } = useApiErrors();
      const { userId, messageId } = toRefs(props);
      const retryMessage = useGenerateChatsAction<{ userId: string; messageId: string }>(ChatsAction.RetryMessage);

      const sendMessageAgain = () => {
        retryMessage({ userId: userId.value, messageId: messageId.value }).catch((e) => showError(e));
      };

      return {
        sendMessageAgain,
      };
    },
});

export default RetryMessageButton;
