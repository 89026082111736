<template>
  <prompt
    v-if="contactsExist"
    v-model="model"
    :title="$t('pages.room.spend1Contact.title')"
    ok-text-path="pages.room.spend1Contact.ok"
    @ok="openContacts"
  />
  <prompt
    v-else
    v-model="model"
    hide-cancel
    :title="$t('pages.room.emptyContacts.title')"
    ok-text-path="pages.room.emptyContacts.ok"
    @ok="model = false"
  />
</template>

<script lang="ts" src="./SpendPackagePrompt.ts" />
