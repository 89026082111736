<template>
  <transition name="room-user-contacts">
    <contact-list
      v-if="model"
      v-click-outside="onClickOutside"
      class="room-user-contacts"
      :contacts="contacts"
    />
  </transition>
</template>

<script lang="ts" src="./UserContacts.ts" />
<style lang="scss" src="./userContacts.scss" />
