import { render } from "./ContactList.vue?vue&type=template&id=06972c8d"
import script from "./ContactList.ts?vue&type=script&lang=ts"
export * from "./ContactList.ts?vue&type=script&lang=ts"

import "./contactList.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "06972c8d"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('06972c8d', script)) {
    api.reload('06972c8d', script)
  }
  
  module.hot.accept("./ContactList.vue?vue&type=template&id=06972c8d", () => {
    api.rerender('06972c8d', render)
  })

}

script.__file = "src/components/ContactList/ContactList.vue"

export default script