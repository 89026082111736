import { render } from "./AddPrivatePhoto.vue?vue&type=template&id=709508a2"
import script from "./AddPrivatePhoto.ts?vue&type=script&lang=ts"
export * from "./AddPrivatePhoto.ts?vue&type=script&lang=ts"

import "./addPrivatePhoto.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "709508a2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('709508a2', script)) {
    api.reload('709508a2', script)
  }
  
  module.hot.accept("./AddPrivatePhoto.vue?vue&type=template&id=709508a2", () => {
    api.rerender('709508a2', render)
  })

}

script.__file = "src/views/Room/components/addPrivatePhoto/AddPrivatePhoto.vue"

export default script