import { defineComponent } from '@vue/runtime-core';

const ButtonMessage = defineComponent({
  props: ['message', 'isSelf'],
  setup() {
    return {};
  },
});

export default ButtonMessage;
