import { BaseMessage } from './BaseMessage';
import { MessageType } from './enums/MessageType';
import type { IMessage } from './interfaces/IMessage';
import type { IMessageAdditional } from './interfaces/IMessageAdditional';
import type { IMessageContent } from './interfaces/IMessageContent';

export class Message extends BaseMessage implements IMessage {
  public static create(raw: IMessage): Message {
    return new Message(raw);
  }

  public type: MessageType.Message;

  public content: IMessageContent<string> & IMessageAdditional;

  public constructor(raw: IMessage) {
    super(raw);

    this.type = MessageType.Message;
    this.content = raw.content;

    this.content.photos = raw.content.photos ?? null;
  }
}
