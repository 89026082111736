import { computed, defineComponent } from 'vue';
import { BtnType } from 'components/Btn';

const RoomPrompt = defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    class: {
      type: [String, Array, Object],
      default: '',
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const classes = computed(() => props.class);

    function close() {
      emit('close');
    }

    return {
      classes,
      close,

      BtnType,
    };
  },
});

export default RoomPrompt;
