import { computed, defineComponent, ref } from 'vue';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { ServiceNames, useServiceSettings } from 'composables/serviceSettings';
import { useI18n } from 'vue-i18n';
import { useCurrency } from 'composables/currency';
import { TxtType } from 'components/Txt';
import { useViewContact } from 'views/Room/eventBus/useViewContact';
import { BuyContactsType } from 'api/userService';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { useApiErrors } from 'composables/apiErrors';
import { toastEmitter, ToastType } from 'components/Toasts';
import { usePageLoader } from 'composables/pageLoader';
import { CHATS_STORE_KEY, ChatsAction } from 'store/chats';
import IconContact from './assets/icon.raw.svg';

const ViewContactsPopup = defineComponent({
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  emits: ['start', 'end'],
  setup(props, { emit }) {
    const { onShowPopup, onClosePopup, closePopup } = useViewContact();
    const { t } = useI18n();
    const { formatCurrency } = useCurrency();
    const store = useStore();
    const { showError } = useApiErrors();

    const model = ref(false);
    onShowPopup(() => {
      model.value = true;
    });
    onClosePopup(() => {
      model.value = false;
    });

    const contact1 = useServiceSettings(ServiceNames.ContactView1, true);
    const contact5 = useServiceSettings(ServiceNames.ContactView5, true);
    const contact10 = useServiceSettings(ServiceNames.ContactView10, true);
    const contactUnlim1 = useServiceSettings(ServiceNames.ContactViewUnlim1Month, true);

    const options = computed(() => [
      {
        title: t('pages.room.expandedPopup.limited', { price: formatCurrency(contact1.value.price), n: 1 }, 1),
        value: ServiceNames.ContactView1,
        priceKey: BuyContactsType.Contacts1,
        btnText: t('pages.room.expandedPopup.button.limited', { n: 1 }, 1),
      },
      {
        title: t('pages.room.expandedPopup.limited', { price: formatCurrency(contact5.value.price), n: 5 }, 5),
        value: ServiceNames.ContactView5,
        priceKey: BuyContactsType.Contacts5,
        btnText: t('pages.room.expandedPopup.button.limited', { n: 5 }, 5),
      },
      {
        title: t('pages.room.expandedPopup.limited', { price: formatCurrency(contact10.value.price), n: 10 }, 10),
        value: ServiceNames.ContactView10,
        priceKey: BuyContactsType.Contacts10,
        btnText: t('pages.room.expandedPopup.button.limited', { n: 10 }, 10),
      },
      {
        title: t('pages.room.expandedPopup.unlimited', { price: formatCurrency(contactUnlim1.value.price), n: 1 }, 1),
        value: ServiceNames.ContactViewUnlim1Month,
        priceKey: BuyContactsType.ContactsUnlim1,
        btnText: t('pages.room.expandedPopup.button.unlimited', { n: 1 }, 1),
      },
    ]);

    const radioModel = ref(ServiceNames.ContactView1);
    const selected = computed(
      () => options.value.find((v) => radioModel.value === v.value),
    );

    const { startLoading, endLoading } = usePageLoader();
    const isFetching = ref(false);

    async function buy() {
      if (!selected.value) return;

      startLoading();
      emit('start');
      isFetching.value = true;
      try {
        await store.dispatch(`${USER_STORE_KEY}/${UsersAction.BuyContact}`, selected.value.priceKey);
        if ([ServiceNames.ContactViewUnlim1Month].includes(selected.value.value)) {
          await store.dispatch(`${CHATS_STORE_KEY}/${ChatsAction.ResetChat}`, props.userId);
        }

        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.room.expandedPopup.success'),
        });
        closePopup();
      } catch (e) {
        showError(e);
      } finally {
        endLoading();
        emit('end');
        isFetching.value = false;
      }
    }

    return {
      model,
      isFetching,
      radioModel,
      selected,
      options,
      closePopup,
      IconContact,
      buy,

      IconName,
      IconType,
      Size,
      TxtType,
    };
  },
});

export default ViewContactsPopup;
