import { render } from "./MessageStatus.vue?vue&type=template&id=6f0c2cec"
import script from "./MessageStatus.ts?vue&type=script&lang=ts"
export * from "./MessageStatus.ts?vue&type=script&lang=ts"

import "./MessageStatus.vue?vue&type=style&index=0&id=6f0c2cec&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6f0c2cec"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6f0c2cec', script)) {
    api.reload('6f0c2cec', script)
  }
  
  module.hot.accept("./MessageStatus.vue?vue&type=template&id=6f0c2cec", () => {
    api.rerender('6f0c2cec', render)
  })

}

script.__file = "src/views/Room/messages/MessageStatus/MessageStatus.vue"

export default script