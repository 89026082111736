import { render } from "./RemoveChat.vue?vue&type=template&id=3219ada0"
import script from "./RemoveChat.ts?vue&type=script&lang=ts"
export * from "./RemoveChat.ts?vue&type=script&lang=ts"

import "./removeChat.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3219ada0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3219ada0', script)) {
    api.reload('3219ada0', script)
  }
  
  module.hot.accept("./RemoveChat.vue?vue&type=template&id=3219ada0", () => {
    api.rerender('3219ada0', render)
  })

}

script.__file = "src/views/Room/components/RemoveChat/RemoveChat.vue"

export default script