<template>
  <div class="room-chat__message-photo">
    <div
      class="room-chat__message-photo__wrapper"
      @click="handleShowPhoto(message.content.private_photo)"
    >
      <img
        class="room-chat__message-photo__image"
        :src="message.content.private_photo[0]"
      >
      <div class="room-chat__message-photo_sender" />
      <div class="room-chat__message-photo__count">
        <h6>
          {{ message.content.private_photo.length }}
        </h6>
        <span>
          {{ $t('pages.room.photo') }}
        </span>
      </div>
    </div>

    <prompt
      v-if="isSelf && message.state !== 'new' && message.state !== 'error'"
      :title="$t('pages.room.closeAccessConfirm.title')"
      ok-text-path="pages.room.closeAccessConfirm.ok"
      cancel-text-path="pages.room.closeAccessConfirm.cancel"
      @ok="closeAccess(message.recipient_id, message.id)"
    >
      <template #activator>
        <div class="room-chat__message-photo_close-access">
          {{ $t('pages.room.closeAccess') }}
        </div>
      </template>
    </prompt>
  </div>
  <photo-viewer
    v-model:active-index="viewerImagesActiveIndex"
    :show="isShowViewer"
    :sources="viewerImages"

    @close="handleCloseViewer"
    />
</template>

<script lang="ts" src="./PrivatePhotoMessage.ts" />
