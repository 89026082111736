import { render } from "./UserContacts.vue?vue&type=template&id=3271aba0"
import script from "./UserContacts.ts?vue&type=script&lang=ts"
export * from "./UserContacts.ts?vue&type=script&lang=ts"

import "./userContacts.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "3271aba0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3271aba0', script)) {
    api.reload('3271aba0', script)
  }
  
  module.hot.accept("./UserContacts.vue?vue&type=template&id=3271aba0", () => {
    api.rerender('3271aba0', render)
  })

}

script.__file = "src/views/Room/components/UserContacts/UserContacts.vue"

export default script