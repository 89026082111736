import { render } from "./BottomModal.vue?vue&type=template&id=06dc7156"
import script from "./BottomModal.ts?vue&type=script&lang=ts"
export * from "./BottomModal.ts?vue&type=script&lang=ts"

import "./bottomModal.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "06dc7156"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('06dc7156', script)) {
    api.reload('06dc7156', script)
  }
  
  module.hot.accept("./BottomModal.vue?vue&type=template&id=06dc7156", () => {
    api.rerender('06dc7156', render)
  })

}

script.__file = "src/components/BottomModal/BottomModal.vue"

export default script