import { defineComponent, toRefs } from '@vue/runtime-core';
import { TrueMessageType } from 'api/rooms/models/message';
import { IconName, IconType } from 'components/Icon';
import { copyToClipboard } from 'core/helpers';
import { MainColor, Size } from 'core/styles';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { ContactType } from 'api/users/models/contact';
import { computed } from 'vue';
import { IAbstractMessage } from '../Interfaces';
import { getOrderTaxiUrl } from '../Helpers';

const ContactMessage = defineComponent({
  props: ['message', 'isSelf'],

  setup(props) {
    const { isSelf, message } = toRefs(props);
    const { t } = useI18n();

    async function copyAddress(address: string) {
      await copyToClipboard(address);
      toastEmitter.emit('toast', {
        type: ToastType.Success,
        message: t('pages.room.addressCopied'),
      });
    }

    const isHidden = computed(() => {
      if (!props || !props.message) return false;
      return !!props.message.contact_is_hidden;
    });

    const getContactHref = (contact: IAbstractMessage): string | undefined => {
      if (isHidden.value) return undefined;

      if (contact.type === ContactType.Telegram) return `https://telegram.me/${contact.content.telegram}`;
      if (contact.type === ContactType.WhatsApp) return `/wa?phone=${contact.content.whatsapp}`;

      return `tel:${contact.content.phone}`;
    };

    const getContactIcon = (type: ContactType): IconName => {
      if (type === ContactType.Telegram) return IconName.Telegram;
      if (type === ContactType.WhatsApp) return IconName.Whatsapp;
      if (type === ContactType.Address) return IconName.Pin;
      return IconName.Phone;
    };

    const getMessageText = (message: any) => message.content[message.type];

    let orderTaxiUrl = '';
    const msg = message.value;
    if (!isSelf.value && msg.type === TrueMessageType.Address) {
      orderTaxiUrl = getOrderTaxiUrl(msg.content);
    }

    return {
      isHidden,
      orderTaxiUrl,

      IconName,
      IconType,
      Size,
      MainColor,
      TrueMessageType,

      getMessageText,
      getContactHref,
      copyAddress,
      getContactIcon,
    };
  },
});

export default ContactMessage;
