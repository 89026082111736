import { computed, defineComponent, onMounted, ref } from 'vue';
import ExpandedAlert from 'components/ExpandedAlert';
import { IconName, IconType } from 'components/Icon';
import { StorageKeys, storageService } from 'storage';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { IProfile } from 'api/users/models/user';

const BUY_CONTACTS_INTERVAL = parseInt(process.env.VUE_APP_BUY_CONTACTS_INTERVAL || '0', 10);

const ViewContactsAlert = defineComponent({
  components: {
    ExpandedAlert,
  },
  emits: ['show-popup'],
  setup(props, { emit }) {
    const store = useStore();
    const profile = computed<IProfile>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);
    const packages = computed(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]);

    const isShow = ref(false);
    const alertRef = ref();

    onMounted(() => {
      if (store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]?.length
        || profile.value.available_contacts_open
        || profile.value.available_see_contacts) {
        isShow.value = true;
        return;
      }

      const date = storageService.getItem(StorageKeys.BuyViewContactsAlert);
      if (!date) {
        isShow.value = true;
        return;
      }
      const parsed = new Date(date);

      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (!parsed || isNaN(parsed)) {
        storageService.removeItem(StorageKeys.BuyViewContactsAlert);
        return;
      }

      isShow.value = parsed.getTime() + BUY_CONTACTS_INTERVAL < Date.now();
    });

    const showPopup = () => {
      emit('show-popup');
      alertRef.value?.toggleExpand();

      if (store.getters[`${USER_STORE_KEY}/${UsersGetter.ProfilePaidPackages}`]?.length
        || profile.value.available_contacts_open
        || profile.value.available_see_contacts) return;
      isShow.value = false;
      storageService.setItem(StorageKeys.BuyViewContactsAlert,
        new Date(Date.now() + BUY_CONTACTS_INTERVAL).toISOString());
    };

    return {
      showPopup,
      alertRef,
      isShow,
      packages,

      IconName,
      IconType,
    };
  },
});

export default ViewContactsAlert;
