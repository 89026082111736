import { defineComponent, toRefs } from '@vue/runtime-core';
import { ChatsAction } from 'store/chats/enums';
import { useGenerateChatsAction } from 'store/chats/hooks';
import { useApiErrors } from 'composables/apiErrors';

const CancelMessageButton = defineComponent({
    props: ['userId', 'messageId'],

    setup(props) {
        const { showError } = useApiErrors();
        const { userId, messageId } = toRefs(props);
        const submitCancelMessage = useGenerateChatsAction<{ userId: string; messageId: string }>(ChatsAction.CancelMessage);

        const cancelMessage = () => {
            submitCancelMessage({ userId: userId.value, messageId: messageId.value }).catch((e) => showError(e));
        };

        return {
            cancelMessage,
        };
    },
});

export default CancelMessageButton;
