<template>
  <div
    v-if="isShow"
    class="room-to-success-users"
  >
    <btn
      class="room-to-success-users__block"
      :class="`room-to-success-users__block--${color}`"
      :to="{ name: RouteNames.SuccessCatalog }"
    >
      <div
        class="room-to-success-users__img-wrapper"
        v-html="image"
      />
      <txt
        :weight="TxtWeight.Bold"
        :type="TxtType.Heading4"
      >
        {{ $t(`pages.room.toSuccessUsers.${gender}`) }}
      </txt>

      <div class="flex-1" />

      <icon-btn @click.stop="handleClose">
        <icon
          :name="IconName.Crosses"
          :type="IconType.Contour"
          :color="MainColor.White"
          :size="Size.Xxs"
        />
      </icon-btn>
    </btn>
  </div>
</template>

<script lang="ts" src="./ToSuccessUsers.ts" />
<style lang="scss" src="./toSuccessUsers.scss" />
