import { render } from "./PrivatePhotoMessage.vue?vue&type=template&id=60c3e2f0"
import script from "./PrivatePhotoMessage.ts?vue&type=script&lang=ts"
export * from "./PrivatePhotoMessage.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "60c3e2f0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('60c3e2f0', script)) {
    api.reload('60c3e2f0', script)
  }
  
  module.hot.accept("./PrivatePhotoMessage.vue?vue&type=template&id=60c3e2f0", () => {
    api.rerender('60c3e2f0', render)
  })

}

script.__file = "src/views/Room/messages/PrivatePhotoMessage/PrivatePhotoMessage.vue"

export default script