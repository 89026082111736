<template>
  <span
    v-if="message.type === TrueMessageType.Address"
    class="room-chat__message-contact_address"
  >
    <icon
      class="room-chat__message-contact-icon"
      :type="IconType.Contour"
      :name="IconName.Pin"
      :color="MainColor.White"
      :size="Size.S"
    />
    <span
      v-if="!isHidden"
    >{{ getMessageText(message) }}</span>
    <span
      v-else
      :class="{ 'room-chat__message_contact-text--blur': isHidden }"
    >*************************</span>
    <icon
      v-if="!isHidden"
      :type="IconType.Contour"
      :name="IconName.Copy"
      :color="MainColor.White"
      :size="Size.S"
      class="room-chat__message-contact-copy-icon"
      @click.prevent.stop="copyAddress(message.content.address)"
    />
  </span>
  <a
    v-else
    :href="getContactHref(message)"
    class="room-chat__message-contact_link"
    :target="getContactHref(message) ? '_blank' : undefined"
  >
    <icon
      class="room-chat__message-contact-icon"
      :type="IconType.Contour"
      :name="getContactIcon(message.type)"
      :color="MainColor.White"
      :size="Size.S"
    />
    <span
      v-if="!isHidden"
    >{{ getMessageText(message) }}</span>
    <span
      v-else
      :class="{ 'room-chat__message_contact-text--blur': isHidden }"
    >*************************</span>
  </a>

  <a
    v-if="orderTaxiUrl && !isHidden"
    class="room-chat__message-contact_action"
    :href="orderTaxiUrl"
    target="_blank"
  >
    {{ $t('pages.room.orderTaxi') }}
  </a>
</template>

<script lang="ts" src="./ContactMessage.ts"></script>
