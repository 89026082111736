import { render } from "./NeedPackagePrompt.vue?vue&type=template&id=42ac300a"
import script from "./NeedPackagePrompt.ts?vue&type=script&lang=ts"
export * from "./NeedPackagePrompt.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "42ac300a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('42ac300a', script)) {
    api.reload('42ac300a', script)
  }
  
  module.hot.accept("./NeedPackagePrompt.vue?vue&type=template&id=42ac300a", () => {
    api.rerender('42ac300a', render)
  })

}

script.__file = "src/views/Room/components/NeedPackagePrompt/NeedPackagePrompt.vue"

export default script