import { ref, defineComponent } from 'vue';
import Prompt from 'components/Prompt';
import { useRouter } from 'vue-router';
import { RouteNames } from 'router/names';
import { useViewContact } from 'views/Room/eventBus/useViewContact';

const NeedPackagePrompt = defineComponent({
  components: {
    Prompt,
  },
  setup() {
    const { onOpenPrompt } = useViewContact();
    const router = useRouter();

    const model = ref(false);
    onOpenPrompt(() => {
      model.value = true;
    });

    function onOk() {
      router.push({ name: RouteNames.ProfileWallet });
    }

    return {
      onOk,
      model,
    };
  },
});

export default NeedPackagePrompt;
