import { render } from "./ViewContactsPopup.vue?vue&type=template&id=b567bb6c"
import script from "./ViewContactsPopup.ts?vue&type=script&lang=ts"
export * from "./ViewContactsPopup.ts?vue&type=script&lang=ts"

import "./viewContactsPopup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "b567bb6c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b567bb6c', script)) {
    api.reload('b567bb6c', script)
  }
  
  module.hot.accept("./ViewContactsPopup.vue?vue&type=template&id=b567bb6c", () => {
    api.rerender('b567bb6c', render)
  })

}

script.__file = "src/views/Room/components/ViewContactsPopup/ViewContactsPopup.vue"

export default script