import { render } from "./RetryMessageButton.vue?vue&type=template&id=2a02c494"
import script from "./RetryMessageButton.ts?vue&type=script&lang=js"
export * from "./RetryMessageButton.ts?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "2a02c494"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('2a02c494', script)) {
    api.reload('2a02c494', script)
  }
  
  module.hot.accept("./RetryMessageButton.vue?vue&type=template&id=2a02c494", () => {
    api.rerender('2a02c494', render)
  })

}

script.__file = "src/views/Room/messages/RetryMessageButton/RetryMessageButton.vue"

export default script