<template>
  <div
    class="room-photo-message"
    :class="{ 'room-photo-message--self': isSelf }"
    :style="alonePhoto && alonePhotoRatio < 1 ? { width: `calc(100% * ${alonePhotoRatio})` } : { paddingTop: `calc(100% * ${1 / alonePhotoRatio})` }"
  >
    <div
      :class="[
        { 'room-photo-message__tiles-wrapper--hidden': loadedCount !== message.content.photo.length },
      ]"
      class="room-photo-message__tiles-wrapper"
    >
      <div class="room-photo-message-tiles">
        <div
          v-for="(photo, key) in message.content.photo"
          :key="photo"
          :style="{ flexBasis: getWrapperWidth(key), height: wrapperHeight }"
          class="room-photo-message-tiles__img-wrapper"
          @click="handleShowPhoto(key)"
        >
          <img
            ref="imageRef"
            class="room-photo-message-tiles__img lazyload"
            :data-src="photo"
            alt="user-photo"
            @load="onLoaded"
          >
        </div>
      </div>
    </div>
    <transition name="room-photo-message">
      <div
        v-show="loadedCount !== message.content.photo.length"
        class="room-photo-message__loading"
      />
    </transition>
  </div>
  <photo-viewer
    v-model:active-index="viewerImagesActiveIndex"
    :show="isShowViewer"
    :sources="viewerImages"
    @close="handleCloseViewer"
  />
</template>

<script lang="ts" src="./PhotoMessage.ts" />
<style lang="scss" src="./photoMessage.scss" />
