import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { Sex } from 'api/users/models/user';

import { IconName, IconType } from 'components/Icon';
import { MainColor, Size } from 'core/styles';
import { TxtType, TxtWeight } from 'components/Txt';
import { RouteNames } from 'router/names';
import FemaleImg from './assets/female.raw.svg';
import MaleImg from './assets/male.raw.svg';

const ToSuccessUsers = defineComponent({
  setup() {
    const store = useStore();

    const gender =
      computed<Nullable<Sex>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]?.oppositeGender);

    const image = computed(() => (gender.value === Sex.Male ? MaleImg : FemaleImg));
    const color = computed(() => (gender.value === Sex.Male ? 'blue' : 'red'));

    const isShow = ref(true);
    function handleClose() {
      isShow.value = false;
    }

    return {
      gender,
      image,
      color,
      isShow,
      handleClose,

      IconName,
      IconType,
      MainColor,
      Size,
      TxtWeight,
      TxtType,
      RouteNames,
    };
  },
});

export default ToSuccessUsers;
