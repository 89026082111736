import { render } from "./BlockUser.vue?vue&type=template&id=f4e5dd5c"
import script from "./BlockUser.ts?vue&type=script&lang=ts"
export * from "./BlockUser.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f4e5dd5c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f4e5dd5c', script)) {
    api.reload('f4e5dd5c', script)
  }
  
  module.hot.accept("./BlockUser.vue?vue&type=template&id=f4e5dd5c", () => {
    api.rerender('f4e5dd5c', render)
  })

}

script.__file = "src/views/Room/components/BlockUser/BlockUser.vue"

export default script