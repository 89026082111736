import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import { MessageStatus } from 'api/rooms/models/message';
import { Room } from 'api/rooms/models/room';
import { Conversation } from 'api/chatsService';
import { IProfile, User } from 'api/users/models/user';
import { IconName, IconType } from 'components/Icon';
import { TextfieldStyle } from 'components/Textfield';
import { TxtType, TxtWeight } from 'components/Txt';
import Empty from 'components/Empty';
import { MainColor, Size } from 'core/styles';
import ListLayout from 'layouts/ListLayout';
import MainLayout from 'layouts/MainLayout';
import { UsersGetter, useUsersGetter } from 'store/users';
import { ChatsGetter } from 'store/chats/enums';
import {
  computed,
  defineComponent,
  ref,
  watchEffect,
} from 'vue';
import { isLovistaAdmin } from 'core/helpers/isLovistaAdmin';
import { useStore } from 'vuex';
import { CHATS_STORE_KEY } from 'store/chats/constants';
import { useRouter } from 'vue-router';
import { RouteNames } from 'router/names';
import { hideScroll } from 'core/helpers';
import Stories from '../Catalog/components/Stories';
import CreateStory from './components/CreateStory';
import Message from './components/Message';
import BlockedUser from './components/BlockedUser';
import ToSuccessUsers from './components/ToSuccessUsers';

interface IListItem {
  user: User;
  room: Room;
}

const Rooms = defineComponent({
  name: RouteNames.Rooms,
  components: {
    ToSuccessUsers,
    MainLayout,
    ListLayout,
    CreateStory,
    Empty,
    Message,
    BlockedUser,
    Stories,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const isCreateStory = ref(false);
    const isStoryOpen = ref(false);
    const isStoriesOpen = ref(false);

    const profile = computed(() => useUsersGetter<IProfile>(UsersGetter.Profile));
    const profileStory = computed(() => profile.value?.highlight);
    const profileStoryExpire = computed(() => {
      if (!profileStory.value) return [];
      const expireAt = dayjs(profileStory.value.expired_at);
      const now = dayjs();
      const hours = expireAt.diff(now, 'hours');
      const minutes = Math.floor(expireAt.diff(now, 'minutes') % 60);
      return [hours, minutes];
    });
    const chats = computed(
      () => store.getters[`${CHATS_STORE_KEY}/${ChatsGetter.Chats}`].filter((chat: Conversation) => !!chat.last_message),
    );

    watchEffect(() => {
      hideScroll(isCreateStory.value || isStoryOpen.value);
    }, { flush: 'post' });

    const handleStory = () => {
      if (!profileStory.value) {
        isCreateStory.value = true;
      } else {
        isStoryOpen.value = true;
      }
    };

    const isSearch = ref(false);
    const search = ref('');

    const findedUsers = ref<User[]>([]);
    const findedMessages = ref<IListItem[]>([]);

    function closeSearch() {
      isSearch.value = false;
      search.value = '';
    }

    const getUser = (chat: Conversation) => (chat.opposite_user);
    const getSender = (chat: Conversation) => (profile.value.id === chat.last_message?.sender_id ? profile.value : chat.opposite_user);
    const getFullName = (chat: Conversation) => {
      const { name, age, id, deleted_at, blocked_at } = getUser(chat);
      if (isLovistaAdmin(id) || deleted_at || blocked_at) return name;
      return `${name}, ${age} ${t('user.age', age)}`;
    };
    const onClickConversation = (chat: Conversation) => {
      const user = getUser(chat);
      router.push({ name: 'room', params: { id: user.id } });
    };

    return {
      isLovistaAdmin,
      isStoriesOpen,
      isCreateStory,
      isStoryOpen,
      handleStory,
      profileStory,
      profileStoryExpire,
      profile,
      getSender,
      getUser,
      getFullName,
      onClickConversation,
      chats,
      isSearch,
      search,
      findedUsers,
      findedMessages,
      closeSearch,
    };
  },

  data() {
    return {
      Size,
      MainColor,
      IconType,
      IconName,
      TxtType,
      TxtWeight,
      MessageStatus,
      TextfieldStyle,
    };
  },
});

export default Rooms;
