<template>
    <div class="room_chat__button_message_title" v-if="message.content.title" type="h4">{{ message.content.title }}</div>
    <div>{{ message.content.text }}</div>
    <div v-if="message.content.picture" class="room_chat__button_message_img">
        <img :src="message.content.picture" />
    </div>
    <div class="room_chat__button_message_action_wrapper" v-if="message.content.button">
      <div class="room_chat__button_message_action" v-if="message.content.button">
        <a
          class="btn btn-active"
          :href="message.content.button.action"
          :target="message.content.button.external ? '_blank': '_self'"
        >
          {{ message.content.button.caption }}
        </a>
      </div>
    </div>
</template>

<script type="ts" src="./ButtonMessage.ts"></script>
